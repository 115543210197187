import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { LocalStorageUtils, Utils } from "../core/utils";
import { TranslationService } from "../services/translation.service";
import { CourseService } from "../services/course.service";

@Component({
  selector: "app-categories",
  templateUrl: "./categories.component.html",
  styleUrls: ["./categories.component.scss"],
})
export class CategoriesComponent {
  constructor(
    private router: Router,
    public translationService: TranslationService,
    private courseService: CourseService,
  ) {
    Utils.checkAuthorization(this.router);
    this.courseService.deleteCourseBuilderSteps();
    this.courseService.applyStyles();
  }
  mode!: string;
  eventName!: string;
  categories: any[] = [
    {
      name: this.translationService.translate("EDE_COURSE_CONTENT"),
      id: "edeCourseContent",
      disabled: false,
    },
    // {
    //   name: this.translationService.translate("TBS_COURSE_CONTENT"),
    //   id: "tbsCourseContent",
    //   disabled: true,
    // },
    // {
    //   name: this.translationService.translate("BDL_COURSE_CONTENT"),
    //   id: "bdlCourseContent",
    //   disabled: true,
    // },
    {
      name: this.translationService.translate("QUIZ_QSSP"),
      id: "quiz",
      disabled: false,
    },
    {
      name: this.translationService.translate("QUESTIONNIRE"),
      id: "questionnaire",
      disabled: true,
    },
    {
      name: this.translationService.translate("ETC"),
      id: "etc",
      disabled: true,
    },
  ];

  categoryOnClick(id: string): void {
    this.router.navigate([`/${id}`]);
  }
}

import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class SlideService {
  public _stopAudioPlayer = new Subject<boolean>();
  public _stopVideoPlayer = new Subject<boolean>();
  public _stopAudioPlayerLayer2 = new Subject<boolean>();
  constructor() {}
}

import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  inject,
} from "@angular/core";
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { Option } from "../../../../create-course/create-course.interfaces";
import { COURSE_CONST, PART_1_SUBTITLES } from "src/app/create-course/data";
import { GlobalService } from "src/app/services/global.service";

@Component({
  selector: "app-quiz-content-editor",
  templateUrl: "./quiz-content-editor.component.html",
  styleUrls: ["./quiz-content-editor.component.scss"],
})
export class QuizContentEditorComponent implements OnInit {
  courseConst = COURSE_CONST;
  currentLayerOptions!: Option[];
  totalParts: string = COURSE_CONST.ONE;
  subtitle: any = PART_1_SUBTITLES;
  @Input() parentForm!: UntypedFormGroup;
  @Input() questionType!: Option;
  @Input() enableFreeFormAnswer: boolean = false;
  @Input() componentName: string = COURSE_CONST.STEP_TWO;
  @Input() isFormSubmit: boolean = false;
  @Output() formSubmitted: EventEmitter<boolean> = new EventEmitter();

  private readonly globalService = inject(GlobalService);
  private readonly fb = inject(FormBuilder);
  tags: string[] = [];

  ngOnInit(): void {
    this.toggleFreeFormAnswer(this.enableFreeFormAnswer);
    const minimunAnswer = 2;

    if (
      ["1", "2"].includes(this.questionType.value) &&
      !this.parentForm.get("answerLabels")?.value?.length
    ) {
      for (let i = 0; i < minimunAnswer; i++) {
        this.addOption();
      }
    }

    if (
      this.questionType.value == "4" &&
      !this.parentForm.get("horizontalLabels")?.value?.length
    ) {
      for (let i = 0; i < minimunAnswer; i++) {
        this.addHorizontalLabel();
      }
    }
  }

  toggleFreeFormAnswer(enable: boolean) {
    const freeFormQuestionControl = this.parentForm.get("freeFormQuestion");
    if (freeFormQuestionControl) {
      // Check if freeFormQuestionControl is not null or undefined
      if (enable) {
        freeFormQuestionControl.setValidators([
          Validators.required,
          Validators.maxLength(200),
        ]);
      } else {
        freeFormQuestionControl.clearValidators(); // Safely call clearValidators
      }
      freeFormQuestionControl.updateValueAndValidity();
    }
  }

  getFormControl(control: AbstractControl, key: string): FormControl {
    const formControl = control.get(key) as FormControl;
    return formControl || new FormControl(); // Return a new FormControl if null
  }

  get answers(): FormArray {
    return this.parentForm.get("answerLabels") as FormArray;
  }

  get horizontalLabels(): FormArray {
    return this.parentForm.get("horizontalLabels") as FormArray;
  }

  addHorizontalLabel() {
    const index = this.horizontalLabels.controls.length;

    if (index >= 10) {
      this.globalService.informationModal(
        "Answer limit reached",
        "The number of answers allowed is 10.",
      );
    } else {
      const newLabel = `Option ${index + 1}`;
      const newPoint = index + 1; // Assuming point increments with each label
      const labelObject = this.fb.group({
        label: [newLabel, [Validators.maxLength(200), Validators.required]],
        point: [newPoint, [Validators.min(0), Validators.required]],
      });

      this.horizontalLabels.push(labelObject);
    }
  }

  get tagControls() {
    const tagsFormArray = this.parentForm.get("tags") as FormArray;
    return tagsFormArray ? tagsFormArray.controls : [];
  }

  removeHorizontalLabel(i: any): void {
    // const index = this.options.controls.length;
    const index = this.horizontalLabels.controls.length;
    if (index <= 2) {
      this.globalService.informationModal(
        "Answer minimun required",
        "The number of minimum answers allowed is 2.",
      );
    } else {
      this.horizontalLabels.removeAt(i);
      // this.horizontalPoints.removeAt(i);
    }
  }

  addOption() {
    const index = this.answers.controls.length;
    if (index >= 10) {
      this.globalService.informationModal(
        "Answer limit reached",
        "The number of answers allowed is 10.",
      );
    } else {
      const group = this.fb.group({
        label: [
          `Option ${index + 1}`,
          [Validators.maxLength(200), Validators.required],
        ],
        correct_answer: [false], // Assuming this is a boolean for the checkbox
      });

      this.answers.push(group);
    }
  }

  removeOption(i: any): void {
    const index = this.answers.controls.length;
    if (index <= 2) {
      this.globalService.informationModal(
        "Answer minimun required",
        "The number of minimum answers allowed is 2.",
      );
    } else {
      // this.options.removeAt(i);
      this.answers.removeAt(i);
    }
  }

  disableOptions(index: number) {
    const control = this.answers.controls[index].get("correct_answer");
    const isChecked = control ? control.value : false;
    if (+this.questionType.value == 1) {
      for (let i = 0; i < this.answers.controls.length; i++) {
        const ctrl = this.answers.controls[i].get("correct_answer");
        if (i !== index && isChecked) {
          ctrl?.disable();
        } else {
          if (isChecked) {
            for (let j = 0; j < this.answers.controls.length; j++) {
              if (j !== index) {
                this.answers.controls[j]
                  ?.get("correct_answer")
                  ?.setValue(false);
              }
            }
          }
          ctrl?.enable();
        }
      }
    }
  }

  submitForm() {
    this.formSubmitted.emit(true);
  }

  validateText() {
    return (
      this.parentForm?.get("editor")?.hasError("required") &&
      this.parentForm?.get("editor")?.touched
    );
  }

  addTag(tagInputValue: string): void {
    if (tagInputValue.trim()) {
      const control = new FormControl(tagInputValue.trim(), [
        Validators.required,
      ]);
      (<FormArray>this.parentForm.get("tags")).push(control);

      (document.getElementById("tagInput") as HTMLInputElement).value = "";
    }
  }

  removeTag(index: number): void {
    const tagsArray = this.parentForm.get("tags") as FormArray;
    `1 `;
    tagsArray.removeAt(index);
  }

  onInputFocus(event: FocusEvent): void {
    // Use target to access the element that triggered the event
    (event.target as HTMLInputElement).select();
  }
}

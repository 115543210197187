import { Component, EventEmitter, Input, Output } from "@angular/core";
import { Router } from "@angular/router";
import { LocalStorageUtils, Utils } from "src/app/core/utils";
import { CourseService } from "src/app/services/course.service";

@Component({
  selector: "app-quiz-step-zero",
  templateUrl: "./quiz-step-zero.component.html",
  styleUrls: ["./quiz-step-zero.component.scss"],
})
export class QuizStepZeroComponent {
  @Output() addAnotherSlide: EventEmitter<any> = new EventEmitter();
  @Output() finish: EventEmitter<any> = new EventEmitter();
  @Output() preview: EventEmitter<any> = new EventEmitter();
  @Input() flgPreview: boolean = false;

  constructor(
    private router: Router,
    private courseService: CourseService,
  ) {
    Utils.checkAuthorization(this.router);
    this.courseService.applyStyles();
  }

  handleAddQuestion(): void {
    this.addAnotherSlide.emit();
  }

  handlePreview(): void {
    this.preview.emit();
  }
  handleExit(): void {
    this.router.navigate(["/"]);
  }

  handleFinish(): void {
    this.finish.emit();
  }

  submitForm(): boolean {
    return true;
  }
}

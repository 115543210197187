import { Component, Inject, OnInit } from "@angular/core";
import { NZ_MODAL_DATA } from "ng-zorro-antd/modal";
import { Utils } from "src/app/core/utils";
import { CourseService } from "src/app/services/course.service";
import { Router } from "@angular/router";

@Component({
  selector: "app-layer-two-modal",
  templateUrl: "./layer-two-modal.component.html",
  styleUrls: ["./layer-two-modal.component.scss"],
})
export class LayerTwoModalComponent implements OnInit {
  text: string = "";
  image: string = "";
  styleObject: any;

  constructor(
    @Inject(NZ_MODAL_DATA) public data: any,
    private courseService: CourseService,
    private router: Router,
  ) {
    this.text = data.text;
    this.image = data.image;
  }
  ngOnInit(): void {
    Utils.checkViewerAuthorization(this.router);
    this.courseService.applyStyles();
  }
}

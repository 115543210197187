import { Component } from "@angular/core";
import { CourseService } from "src/app/services/course.service";

@Component({
  selector: "app-spinner",
  templateUrl: "./spinner.component.html",
  styleUrls: ["./spinner.component.scss"],
})
export class SpinnerComponent {
  constructor(private courseService: CourseService) {}

  ngOnInit(): void {
    this.courseService.applyStyles();
  }
}

import {
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import { VideoPlayerOption } from "src/app/models/video-player.interface";
import { SlideService } from "src/app/services/slide.service";
import videojs from "video.js";

@Component({
  selector: "app-video-player",
  templateUrl: "./video-player.component.html",
  styleUrls: ["./video-player.component.scss"],
})
export class VideoPlayerComponent implements OnInit, OnDestroy {
  @ViewChild("target", { static: true }) target!: ElementRef;
  @Input() options!: VideoPlayerOption;
  @Input() isAudioPlayerAvailable: boolean = false;
  player: any;
  playerInitialized: boolean = true;
  playerLoadingTime: number = 3000;

  constructor(public slideService: SlideService) {}

  ngOnInit() {
    this.player = videojs(this.target.nativeElement, this.options, () => {
      this.player.on("play", () => {
        this.slideService._stopAudioPlayer.next(true);
        this.slideService._stopAudioPlayerLayer2.next(true);
      });
    });

    this.slideService._stopVideoPlayer.subscribe((state) => {
      if (state) {
        if (this.player) {
          this.player.pause();
        }
      }
    });
  }

  ngOnDestroy() {
    if (this.player) {
      this.player.dispose();
    }
  }
}

import { Component, OnInit } from "@angular/core";
import { CourseService } from "src/app/services/course.service";
import { TranslationService } from "src/app/services/translation.service";

@Component({
  selector: "app-language-selector",
  templateUrl: "./language-selector.component.html",
  styleUrls: ["./language-selector.component.scss"],
})
export class LanguageSelectorComponent implements OnInit {
  selectedLanguage: string = "en";
  languageData: any = [
    {
      name: "English",
      code: "EN",
      icon: "https://cdn.countryflags.com/thumbs/united-states-of-america/flag-800.png",
    },
    {
      name: "French",
      code: "FR",
      icon: "https://cdn.countryflags.com/thumbs/france/flag-800.png",
    },
  ];

  constructor(public translationService: TranslationService) {}

  ngOnInit() {}

  changeLanguage(languageObj: any): void {
    this.languageData = this.languageData.filter(
      (x: any) => x.code != languageObj.code,
    );
    this.languageData.unshift(languageObj);
    this.selectedLanguage = languageObj.code.toLowerCase();
    this.translationService
      .loadTranslations(languageObj.code.toLowerCase())
      .subscribe();
  }
}

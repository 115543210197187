import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { Subscription } from "rxjs";
import { AudioRecordingService } from "src/app/services/audio-recording.service";
import { SlideService } from "src/app/services/slide.service";
import { environment } from "src/environments/environment";
@Component({
  selector: "app-audio-recording",
  templateUrl: "./audio-recording.component.html",
  styleUrls: ["./audio-recording.component.scss"],
})
export class AudioRecordingComponent implements OnInit, OnChanges, OnDestroy {
  isRecording = false;
  recordedTime!: any;
  blobUrl!: any;
  teste!: any;
  isCountDownEnable: boolean = false;
  serviceSubscription: any = new Subscription();
  countDown: number = environment.audioConfig.audioRecorderCountdown;
  recordingStartTimer: number = 0;
  @Input() indexOfAudioEditor: number = 0;
  @Input() indexOfContentEditor: number = 0;
  @Output() emitRecordedFile: any = new EventEmitter();
  @Input() audioValue: any = [];
  @Input() disabled: boolean = false;

  constructor(
    private audioRecordingService: AudioRecordingService,
    public slideService: SlideService,
    private sanitizer: DomSanitizer,
  ) {}

  ngOnInit(): void {
    this.recordingStartTimer = environment.audioConfig.audioRecorderCountdown;
    this.serviceSubscription.add(
      this.audioRecordingService
        .recordingFailed()
        .subscribe(() => (this.isRecording = false)),
    );

    this.serviceSubscription.add(
      this.audioRecordingService
        .getRecordedTime()
        .subscribe((time) => (this.recordedTime = time)),
    );

    this.serviceSubscription.add(
      this.audioRecordingService.getRecordedBlob().subscribe((data) => {
        this.teste = data;
        this.blobUrl = this.sanitizer.bypassSecurityTrustUrl(
          URL.createObjectURL(data.blob),
        );
        // If you want user to verify recording before pushing it to contentEditorComponent
        // Comment below line
        this.saveRecording();
      }),
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.blobUrl = null;
  }

  startRecording() {
    if (!this.isRecording) {
      this.isCountDownEnable = true;
      this.audioRecordingService.startTimer();
      this.countDown = environment.audioConfig.audioRecorderCountdown;
      this.countdown();
      setTimeout(() => {
        this.audioRecordingService.clearTimer();
        this.isCountDownEnable = false;
        if (!this.isRecording) {
          this.isRecording = true;
          this.audioRecordingService.startRecording();
        }
      }, environment.audioConfig.audioRecorderCountdown * 1000);
    }
  }

  abortRecording() {
    if (this.isRecording) {
      this.isRecording = false;
      this.audioRecordingService.abortRecording();
    }
  }

  stopRecording() {
    if (this.isRecording) {
      this.audioRecordingService.stopRecording();
      this.isRecording = false;
    }
  }

  clearRecordedData() {
    this.blobUrl = null;
  }

  ngOnDestroy(): void {
    this.abortRecording();
    this.serviceSubscription.unsubscribe();
  }

  blobToFile(theBlob: Blob, fileName: string): File {
    const audioBlob: any = theBlob;
    audioBlob.lastModifiedDate = new Date();
    audioBlob.name = fileName;
    return theBlob as File;
  }

  saveRecording() {
    if (this.indexOfAudioEditor == this.indexOfContentEditor) {
      this.emitRecordedFile.emit(
        this.blobToFile(
          this.teste.blob,
          "Audio_" + new Date().getTime() + ".mp3",
        ),
      );
    }
    this.blobUrl = null;
  }

  download(): void {
    const url = window.URL.createObjectURL(this.teste.blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = this.teste.title;
    link.click();
  }

  countdown() {
    var countdownInterval = setInterval(() => {
      this.countDown--;
      if (this.countDown < 0) {
        clearInterval(countdownInterval);
      }
    }, 1000);
  }
}

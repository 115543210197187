import { Component, Inject, Input } from "@angular/core";
import { ImgHotspot } from "../img-hotspot";
import { NZ_MODAL_DATA, NzModalRef } from "ng-zorro-antd/modal";
import { TranslationService } from "src/app/services/translation.service";

@Component({
  selector: "app-manage-hotspot-img",
  templateUrl: "./manage-hotspot-img.component.html",
  styleUrls: ["./manage-hotspot-img.component.scss"],
})
export class ManageHotspotImgComponent {
  @Input() imageUrl: string = "";
  @Input() hotspots: ImgHotspot<string>[] = [];
  selectedHotspot?: ImgHotspot<string>;

  clickEvents: string[] = [];

  constructor(
    public translationService: TranslationService,
    @Inject(NZ_MODAL_DATA) public data: any,
    private modalRef: NzModalRef,
  ) {
    this.hotspots = data.hotspots;
    this.imageUrl = data.imageUrl;
  }

  handleHotspotsChange($event: ImgHotspot[]) {
    if (this.hotspots?.length < 3) {
      this.hotspots = $event;
    }
  }

  deleteHotspot(hotspot: ImgHotspot) {
    this.hotspots = this.hotspots.filter((h) => h !== hotspot);
  }

  closeModal() {
    this.modalRef.destroy(this.hotspots);
  }
}

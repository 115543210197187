<div [formGroup]="parentForm">
  <div class="card-display" nz-row [nzGutter]="[24, 24]">
    <div
      nz-col
      class="gutter-row p-0"
      [nzXs]="24 / 1"
      [nzSm]="24 / 1"
      [nzMd]="24 / 1"
      [nzLg]="24 / 1"
    >
      <div>
        <nz-form-item>
          <nz-alert
            nzType="warning"
            nzMessage="Instructions"
            [nzDescription]="descriptionTpl"
            nzShowIcon
          >
            <ng-template #descriptionTpl>
              <div>- {{ "INSTRUCTION_MINIMUM_ANSWER" | translate }}2</div>
              <div>- {{ "INSTRUCTION_MAXIMUM_ANSWER" | translate }}10</div>
              <div>
                -
                {{
                  "INSTRUCTION_MINIMUM_CHARACTER_PER_QUESTION" | translate
                }}800
              </div>
              <div>
                -
                {{ "INSTRUCTION_MAXIMUM_CHARACTER_PER_ANSWER" | translate }}200
              </div>
            </ng-template>
            ></nz-alert
          >
        </nz-form-item>
      </div>
    </div>
    <div
      nz-col
      class="gutter-row p-0"
      [nzXs]="24 / 1"
      [nzSm]="24 / 1"
      [nzMd]="24 / 1"
      [nzLg]="24 / 1"
      *ngIf="componentName!=courseConst.STEP_THREE"
    >
      <nz-form-item>
        <nz-form-label [nzRequired]="false" nzFor="questionTitle">{{
          "QUESTION_TITLE" | translate
        }}</nz-form-label>
        <nz-form-control nzErrorTip="{{ 'ADD_LAYER_TITLE' | translate }}">
          <input
            nz-input
            formControlName="questionTitle"
            id="questionTitle"
            style="height: 40px" 
            maxlength="200"
          />
        </nz-form-control>
      </nz-form-item>
    </div>
    <div
      nz-col
      class="gutter-row p-0"
      [nzXs]="24 / 1"
      [nzSm]="24 / 1"
      [nzMd]="24 / 1"
      [nzLg]="24 / 1"
    >
      <nz-form-item>
        <nz-form-label [nzRequired]="true" nzFor="question">{{
          "QUESTION_TEXT" | translate
        }}</nz-form-label>
        <nz-form-control nzErrorTip="{{ 'ADD_LAYER_TITLE' | translate }}">
          <input
            nz-input
            formControlName="question"
            id="question"
            style="height: 40px"
            maxlength="800"
          />
        </nz-form-control>
      </nz-form-item>

      <nz-form-item *ngIf="enableFreeFormAnswer">
        <nz-form-label [nzRequired]="enableFreeFormAnswer" nzFor="question">{{
          "FREE_FORM_QUESTION" | translate
        }}</nz-form-label>
        <nz-form-control nzErrorTip="{{ 'ADD_LAYER_TITLE' | translate }}">
          <input
            nz-input
            formControlName="freeFormQuestion"
            id="freeFormQuestion"
            style="height: 40px"
            maxlength="200"
          />
        </nz-form-control>
      </nz-form-item>

      <nz-form-item>
        <nz-form-label [nzRequired]="false" nzFor="tagInput">{{ "TAGS" | translate }}</nz-form-label>
        <nz-form-control>
            <div style="display: flex; align-items: center;">
                <input
                  nz-input
                  #tagInputRef
                  id="tagInput"
                  style="height: 40px; flex: 1;"
                  maxlength="200"
                />
                <button
                nz-button
                nzType="primary"
                nzSize="large" 
                (click)="addTag(tagInputRef.value)" 
                style="margin-left: 8px;">{{ "ADD" | translate }}
                <span nz-icon nzType="right" nzTheme="outline"></span>
              </button>
            </div>
        </nz-form-control>
        <div class="tags-container">
          <span *ngFor="let tag of tagControls let i = index" class="tag" [ngStyle]="{ 'background-color': 'primary' }">
            {{ tag.value }}
            <button nz-button nzSize="small" nzType="text" (click)="removeTag(i)">X</button>
          </span>
      </div>
    </nz-form-item>
    </div>
  </div>

  <ng-container *ngIf="['1', '2'].includes(questionType.value)">
    <nz-table nzTemplateMode formArrayName="answerLabels">
      <thead>
        <tr>
          <th><span style="color:red; font-size:20px">* </span> Label</th>
          <th><span style="color:red; font-size:20px">* </span> Correct Answer</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        <!-- <tr *ngFor="let option of answers.controls; index as i">
          
          <td formArrayName="answerLabels">
            <nz-form-control nzErrorTip="Maximum characters per question: 200">
              <input nz-input formControlName="label" />
            </nz-form-control>
          </td>
          <td>
            <nz-form-control>
              <label
                nz-checkbox
                nzValue="i"
                [formControlName]="i"
                (change)="disableOptions(i)"
              ></label>
            </nz-form-control>
          </td>
          <td>
            <button
              (click)="removeOption(i)"
              nz-button
              nzType="primary"
              nzShape="circle"
            >
              <span nz-icon nzType="delete"></span>
            </button>
          </td>
        </tr>  -->
        

        <tr *ngFor="let option of answers.controls; let i = index" [formGroupName]="i">
          
          <td>
            <nz-form-control nzErrorTip="Maximum characters per question: 200">
              <input nz-input formControlName="label" (focus)="onInputFocus($event)" (click)="onInputFocus($event)" />
            </nz-form-control>
          </td>
          <td>
            <nz-form-control>
              <label nz-checkbox nzValue="i" (nzCheckedChange)="disableOptions(i)" [formControlName]="'correct_answer'"></label>
            </nz-form-control>
          </td>
          <td>
            <button (click)="removeOption(i)" nz-button nzType="primary" nzShape="circle">
              <span nz-icon nzType="delete"></span>
            </button>
          </td>
        </tr>
        
        <!-- <ng-container *ngFor="let control of answers.controls; let i = index">
      
       
       <tr *ngFor="let control of answers.controls; let i = index" [formGroupName]="i">
        {{control.controls.value}}
        <td formArrayName="answerLabels">
          <nz-form-control nzErrorTip="Maximum characters per question: 200">
            <input nz-input formControlName="label" />
          </nz-form-control>
        </td>
        <td formArrayName="answerLabels">
          <nz-form-control>
            <label nz-checkbox [formControlName]="i" (change)="disableOptions(i)"></label>
          </nz-form-control>
        </td>
        <td>
          <button (click)="removeOption(i)" nz-button nzType="primary" nzShape="circle">
            <span nz-icon nzType="delete"></span>
          </button>
        </td>
      </tr> -->
      

        <!-- <ng-container *ngFor="let control of answers.controls; let i = index">
          <tr>
            <td>
              <input *ngIf="getFormControl(control, 'label')" type="text" [formControl]="getFormControl(control, 'label')" />
            </td>
            <td>
              <input *ngIf="getFormControl(control, 'correct_answer')" type="checkbox" [formControl]="getFormControl(control, 'correct_answer')" />
            </td>
          </tr>
        </ng-container> -->
        

      </tbody>
    </nz-table>
    <button
      class="add-option-btn"
      (click)="addOption()"
      nz-button
      nzType="primary"
    >
      Add option
    </button>
  </ng-container>

  <ng-container *ngIf="questionType.value == '4'">
    <nz-table nzTemplateMode formArrayName="horizontalLabels">
      <thead>
        <tr>
          <th><span style="color:red; font-size:20px"> * </span> Label</th>
          <th><span style="color:red; font-size:20px"> * </span>Point</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        <!-- <tr *ngFor="let label of horizontalLabels.controls; index as i">
          <td>
            <nz-form-control
              nzErrorTip="Maximum characters per answer option: 200"
            >
              <input
                nz-input
                [formControlName]="'label'"
                placeholder="Insert option name"
              />
            </nz-form-control>
          </td>
          <td>
            <nz-form-control nzErrorTip="Minimum value is 0">
              <input
                type="number"
                nz-input
                [formControlName]="'point'"
                class="horizontal-point"
              />
            </nz-form-control>
          </td>
          <td>
            <button
              (click)="removeHorizontalLabel(i)"
              nz-button
              nzType="primary"
              nzShape="circle"
            >
              <span nz-icon nzType="delete"></span>
            </button>
          </td>
        </tr> -->
        <tr *ngFor="let label of horizontalLabels.controls; let i = index" [formGroupName]="i">
          <td>
            <nz-form-control nzErrorTip="Maximum characters per answer option: 200">
              <input nz-input formControlName="label" placeholder="Insert option name" (focus)="onInputFocus($event)" (click)="onInputFocus($event)"/>
            </nz-form-control>
          </td>
          <td>
            <nz-form-control nzErrorTip="Minimum value is 0">
              <input type="number" nz-input formControlName="point" class="horizontal-point" />
            </nz-form-control>
          </td>
          <td>
            <button (click)="removeHorizontalLabel(i)" nz-button nzType="primary" nzShape="circle">
              <span nz-icon nzType="delete"></span>
            </button>
          </td>
        </tr>
        
      </tbody>
    </nz-table>
    <button
      class="add-option-btn"
      (click)="addHorizontalLabel()"
      nz-button
      nzType="primary"
    >
      Add option
    </button>
  </ng-container>
</div>

// src/app/services/auth.service.ts

import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { shareReplay, tap } from "rxjs/operators";
import { environment } from "../../environments/environment";
import { AuthRequest, AuthResponse } from "../models/auth-response.interface";
import { LocalStorageUtils } from "../core/utils";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  private apiUrl = environment.apiUrl;
  private authResponse$: Observable<AuthResponse> | undefined;

  constructor(private http: HttpClient) {}

  validateSecretKey(data: AuthRequest): Observable<AuthResponse> {
    // Delete existing data from localStorage
    LocalStorageUtils.deleteFromLocalStorage("authData");

    const url = `${this.apiUrl}handle-user/get-access-validateSecretKey`;
    this.authResponse$ = this.http.post<AuthResponse>(url, data).pipe(
      tap((response) => {
        // Save to localStorage
        LocalStorageUtils.saveToLocalStorage(
          "authData",
          JSON.stringify(response),
        );
      }),
      shareReplay(1), // Cache the last emitted value and share it with multiple subscribers
    );

    return this.authResponse$;
  }
  // isAuthenticated(): Observable<AuthResponse> {
  //   let data = {
  //     jwtToken:
  //       'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9uYW1lIjoiNTk3NjUiLCJzdWIiOiI1OTc2NSIsImZpcnN0TmFtZSI6IlBhdWwiLCJsYXN0TmFtZSI6Ik1pbmlvdGFzIiwiZW1haWwiOiIiLCJyb2xlIjoiTWFuYWdlciIsImF1ZCI6InBlbnNpdm8iLCJqdGkiOiJhNWE0YzkyOS00YzhjLTRkYzMtOTYxZS1jYWNkNjA1NjFkYWEiLCJleHAiOjE3MzI3NzA0NDgsImlzcyI6Imh0dHBzOi8vcGVuc2l2by5jb20vIn0.2RUiC64WqLiH8HHVw7NWt05S4idu2B_qTGzRY0ipNSY',
  //   };
  //   const url = `${this.apiUrl}handle-user/get-access`;
  //   this.authResponse$ = this.http.post<AuthResponse>(url, data).pipe(
  //     tap((response) => {
  //       console.log('Response:', response);
  //       // Save to localStorage
  //       LocalStorageUtils.saveToLocalStorage(
  //         'authData',
  //         JSON.stringify(response),
  //       );
  //     }),
  //     shareReplay(1), // Cache the last emitted value and share it with multiple subscribers
  //   );

  //   return this.authResponse$;
  // }
}

<div
  class="video-player-parent"
  [ngClass]="{
    'height-adjusted-video': isAudioPlayerAvailable,
    'full-height-video': !isAudioPlayerAvailable
  }"
>
  <video
    #target
    controls
    playsinline
    preload="metadata"
    class="video-js"
    [ngClass]="{
      'video-js-container': playerInitialized
    }"
  ></video>
</div>


<div>
  <audio
    *ngIf="!openedInModal"
    class="audio-player-width"
    #AudioPlayer
    controls
    muted
    [autoplay]="audioAutoplay"
  >
    <source [src]="audioSource" type="audio/webm" />
  </audio>

  <audio
    *ngIf="openedInModal"
    class="audio-player-width"
    #ModalAudioPlayer
    controls
    muted
    [autoplay]="audioAutoplay"
  >
    <source [src]="audioSource" type="audio/webm" />
  </audio>
</div> 
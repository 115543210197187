import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { HotspotImgComponent } from "./hotspot-img/hotspot-img.component";
import { HotspotComponent } from "./hotspot/hotspot.component";
import { DraggableDirective } from "./draggable.directive";
import { PointsPipe } from "./points.pipe";
import { TranslateDirective } from "./translate.directive";
import { ResizeObserverModule } from "@ng-web-apis/resize-observer";
import { ManageHotspotImgComponent } from "./manage-hotspot-img/manage-hotspot-img.component";
import { FormsModule } from "@angular/forms";
import { NzAlertModule } from "ng-zorro-antd/alert";
import { SharedModule } from "../shared/shared.module";
// import { TranslationPipe } from '../core/pipes/translation.pipe';

@NgModule({
  declarations: [
    HotspotImgComponent,
    HotspotComponent,
    DraggableDirective,
    PointsPipe,
    TranslateDirective,
    ManageHotspotImgComponent,
  ],
  imports: [
    CommonModule,
    ResizeObserverModule,
    FormsModule,
    NzAlertModule,
    SharedModule,
  ],
  exports: [HotspotImgComponent, ManageHotspotImgComponent],
})
export class HotspotImgModule {}

import { Injectable } from "@angular/core";
import { NzModalService } from "ng-zorro-antd/modal";
import { TranslationService } from "./translation.service";

@Injectable({
  providedIn: "root",
})
export class GlobalService {
  constructor(
    public modalService: NzModalService,
    public translationService: TranslationService,
  ) {}

  generateVideoThumbnail(
    file: Blob | MediaSource | any,
    seekTo = 0.0,
  ): Promise<string> {
    return new Promise((resolve, reject) => {
      const videoPlayer = document.createElement("video");
      videoPlayer.setAttribute("src", URL.createObjectURL(file));
      videoPlayer.load();
      videoPlayer.addEventListener("error", (ex: any) => {
        reject("error when loading video file" + ex);
      });
      // load metadata of the video to get video duration and dimensions
      videoPlayer.addEventListener("loadedmetadata", () => {
        // seek to user defined timestamp (in seconds) if required specific thumbnail image
        if (videoPlayer.duration < seekTo) {
          reject("video is too short.");
          return;
        }
        // delay seeking or else 'seeked' event won't fire on Safari
        setTimeout(() => {
          videoPlayer.currentTime = seekTo;
        }, 200);
        // extract video thumbnail once seeking is complete
        videoPlayer.addEventListener("seeked", () => {
          // define a canvas to have the same dimension as the video
          const canvas = document.createElement("canvas");
          canvas.width = videoPlayer.videoWidth;
          canvas.height = videoPlayer.videoHeight;
          // draw the video frame to canvas
          const ctx = canvas.getContext("2d");
          ctx?.drawImage(videoPlayer, 0, 0, canvas.width, canvas.height);

          const thumbnailDataURL = canvas.toDataURL("image/png");

          // Optional, Only for testing. Uncommenting will download thumbnail file.
          // this.downloadThumbnail(thumbnailDataURL);

          // return the base64 image
          resolve(thumbnailDataURL);
        });
      });
    });
  }

  downloadThumbnail(dataURL: string): void {
    const link = document.createElement("a");
    link.href = dataURL;
    link.download = "thumbnail.png";
    link.click();
  }

  informationModal(title: string, message: string) {
    this.modalService.create({
      nzTitle: title,
      nzContent: message,
      nzWidth: "40%",
      nzOkText: this.translationService.translate("OK"),
      nzClosable: false,
      nzCancelText: null, // or nzCancelText: ''
      nzOnOk: () => {},
    });
  }

  base64ToBlob(base64: string): Blob {
    const parts = base64.split(";base64,");
    if (parts.length !== 2) {
      console.error("Invalid base64 string format.");
    }

    const contentType = parts[0].split(":")[1];
    const raw = window.atob(parts[1]);
    const rawLength = raw.length;
    const uint8Array = new Uint8Array(rawLength);

    for (let i = 0; i < rawLength; ++i) {
      uint8Array[i] = raw.charCodeAt(i);
    }

    return new Blob([uint8Array], { type: contentType });
  }
}

<div class="card">
    <div class="col-12 text">
      {{ "LOADER_TEXT" | translate }}
    </div>
    <br>
    <br>
    <div>
      <img src="assets/loadingProcess.gif" alt="Loading">
    </div>
</div>
  
<div *ngIf="courseCategory != 'quiz'" class="row parentContent position-relative" [ngClass]="{
    'h-100':
      (assetImageCount == 0 ||
        assetContainsButton ||
        (assetContainsVideo && !this.slideContent.editor)) &&
      !openedInModal &&
      (!isIOS || !this.isTablet),
    'ios-specific-height': isIOS && !openedInModal && isTablet,
    'h-60vh overflow-auto': openedInModal
  }">

  <div *ngIf="slideContent?.editor" [ngClass]="{
      'col-12':
        assetImageCount > 2 || (assetImageCount == 0 && !assetContainsVideo),
      'mb-2 h-48': assetImageCount > 2 && !openedInModal,
      'col-md-6 col-lg-6 col-sm-12 mb-3':
        assetImageCount == 1 ||
        assetImageCount == 2 ||
        assetContainsVideo ||
        assetContainsButton,
      'h-50vh':
        isMobile &&
        (assetImageCount > 0 || assetContainsVideo || assetContainsButton),
      'h-90': assetContainsAudio && !isMobile,
      'h-40': assetImageCount > 2 && previewMode
    }">
    <div class="card text-container" [ngClass]="{
        textOnly: assetImageCount == 0 && !assetContainsVideo,
        textAndOneImage:
          assetImageCount == 1 ||
          assetImageCount == 2 ||
          assetContainsVideo ||
          assetContainsButton,
        imgMorethanOne: assetImageCount > 2,
        'h-100-w-auto': assetImageCount > 2
      }">
      <div class="card-body">
        <div [innerHTML]="slideContent?.editor | sanitize: safeType.HTML" class="maxHeightFull"></div>
      </div>
    </div>
  </div>

  <div [class]="getAssetContainerClasses" [ngClass]="{
      'h-90': assetContainsAudio && !isMobile
    }" *ngIf="
      assetContainsVideo || this.assetImageCount > 0 || assetContainsButton
    ">
    <div *ngFor="let asset of assetArray; let i = index" class="asset-container" [ngClass]="{
        'h-100': assetArray.length == 1 || assetArray.length > 2,
        'w-32': assetArray.length > 2,
        'mb-2 h-48': assetArray.length == 2,
        'w-100 h-50vh mb-2':
          isMobile && !assetContainsVideo && !assetContainsButton,
        'mb-40': isMobile
      }">

      <img *ngIf="assetImageCount > 0 && !slideContent.hotspots" [src]="asset.url" [alt]="'ALT_TEXT_FOR_SLIDE_IMAGE' | translate"
        class="resizeable-img-class" (click)="clickAsset(i)" />
      
      <app-hotspot-img class="resizeable-img-class-relative" *ngIf="assetImageCount > 0 && slideContent?.hotspots?.length > 0"
        [hotspots]="slideContent.hotspots" [editable]="false" (hotspotClick)="clickHotspot($event.data)"
        (missedClick)="handleMissedCount($event.count)">
        <img [src]="slideContent?.images[0].url" alt="Image" />
      </app-hotspot-img>
      
      <app-video-player *ngIf="assetContainsVideo" [options]="{
          autoplay:
            assetContainsAudio && _globalConfig?.enableAudioAutoplay
              ? false
              : autoplayVideo,
          controls: true,
          sources: [{ src: asset.url, type: getVideoFileExtension(asset.url) }]
        }" [isAudioPlayerAvailable]="assetContainsAudio && assetArray.length == 1">
      </app-video-player>
      <div *ngIf="assetContainsButton"
        class="d-flex justify-content-center align-items-center resizeable-img-class flex-wrap p-10 flex-col"
        [ngClass]="{ 'dekstop-btn-container': !isMobile }">
        <button class="button-preview text-wrap" *ngFor="let button of asset; let j = index" (click)="clickAsset(j)"
          nz-button nzSize="large" nzType="primary" [ngClass]="{
            'btn-2': asset.length <= 2,
            'btn-4': asset.length <= 4 && asset.length > 2,
            'btn-6': asset.length <= 6 && asset.length > 4,
            buttonHeightControl: isMobile
          }" [style.backgroundColor]="
            viewedButton.indexOf(j) > -1
              ? buttonConfig?.activeBackgroundColor
              : buttonConfig?.backgroundColor != 'default'
                ? buttonConfig?.backgroundColor
                : courseService.getStyle('primaryColor')
          " [style.color]="
            viewedButton.indexOf(j) > -1
              ? buttonConfig?.activeTextColor
              : buttonConfig?.color != 'default'
                ? buttonConfig?.color
                : courseService.getStyle('primaryTextColor')
          " [style.borderColor]="
            buttonConfig?.borderColor != 'default'
              ? buttonConfig?.borderColor
              : courseService.getStyle('borderColor')
          ">
          {{ button }}
        </button>
      </div>
    </div>
  </div>

  <app-audio-player *ngIf="assetContainsAudio && audioAsset && !isLoading" [audioSource]="audioAsset"
    [audioAutoplay]="_globalConfig?.enableAudioAutoplay" [openedInModal]="openedInModal">
  </app-audio-player>
</div>

<div *ngIf="courseCategory == 'quiz'" class="row parentContent position-relative" [ngClass]="{
  'h-100':
    (assetImageCount == 0 ||
      assetContainsButton ||
      (assetContainsVideo && !this.slideContent.editor)) &&
    !openedInModal &&
    (!isIOS || !this.isTablet),
  'ios-specific-height': isIOS && !openedInModal && isTablet,
  'h-60vh overflow-auto': openedInModal
}">
  <div class=" card quiz-container">
    <div class="container-fluid">

      <div class="tags-container">
        <span *ngFor="let tag of slideContent?.tags let i = index" class="tag"
          [ngStyle]="{ 'background-color': 'primary' }">
          {{ tag }}
        </span>
      </div>

      <h3><span class="red"
          *ngIf="stepOne?.isQuestionMandatoryForQssp">*</span>{{slideContent?.quizContent?.mainQuestion.question}} </h3>

      <div class="horizontal-scale-container" *ngIf="slideContent?.quizContent?.questionType === 'horizontalScale'">
        <ul class="horizontal-scale-answer-list">
          <li class="horizontal-scale-answer-item"
            *ngFor="let answer of slideContent?.quizContent?.horizontalScale; let i = index">
            <label class="horizontal-scale-label">{{ answer.label || " " }}</label>
            <label class="horizontal-scale-label" *ngIf="stepOne?.enableNumberLabels">{{ i + 1 }}</label>
            <input (change)="onRadioChange(answer, slideContent?.quizContent?.horizontalScale)" type="radio"
              name="radio_group" [value]="answer.user_answer" [checked]="answer.user_answer" [disabled]="disabled"
              class="horizontal-scale-custom-radio custom-radio">
          </li>
        </ul>
      </div>

      <div class="container" *ngIf="slideContent?.quizContent?.questionType === 'multiChoice'">
        <ul class="answer-list">
          <li class="answer-item" *ngFor="let answer of slideContent?.quizContent?.multiChoice; let i = index">
            <input (change)="onRadioChange(answer, slideContent?.quizContent?.multiChoice)" type="radio" id="multichoice{{ i }}"
            name="radio_group_{{slideContent?.quizContent.slideId}}" [value]="answer.user_answer" [checked]="answer.user_answer" [disabled]="disabled"
              class="custom-radio">
              <label for="multichoice{{ i }}">{{ answer.label }}</label>
          </li>
        </ul>
      </div>

      <div class="container" *ngIf="slideContent?.quizContent?.questionType === 'multiSelect'">
        <ul class="answer-list">
          <li class="answer-item" *ngFor="let answer of slideContent?.quizContent?.multiSelect; let i = index">
            <input type="checkbox" id="multiSelect{{ i }}" [checked]="answer.user_answer" [value]="answer.user_answer"
              [(ngModel)]="answer.user_answer" [disabled]="disabled" class="custom-checkbox">
              <label for="multiSelect{{ i }}">{{ answer.label }}</label>
            </li>
        </ul>
      </div>

      <div *ngIf="slideContent?.quizContent?.questionType === 'freeFormQuestion'">
        <textarea class="form-control mt-3" [(ngModel)]="slideContent?.quizContent?.mainQuestion.userAnswer"
          name="slideContent?.quizContent?.mainQuestion.userAnswer"
          id="slideContent?.quizContent?.mainQuestion.userAnswer" cols="1" rows="5" [disabled]="disabled"
          [required]="stepOne?.isFreeFormQuestionMandatory"></textarea>
      </div>

      <div
        *ngIf="stepOne?.enableFreeFormAnswer && slideContent && slideContent.quizContent && slideContent.quizContent.commentsFromConfiguration && slideContent.quizContent.commentsFromConfiguration.question">
        <br>
        <h4><span class="red"
            *ngIf="stepOne?.isFreeFormQuestionMandatory">*</span>{{slideContent?.quizContent?.commentsFromConfiguration.question}}
        </h4>
        <textarea class="form-control mt-3" [(ngModel)]="slideContent?.quizContent?.commentsFromConfiguration.userAnswer"
          name="slideContent?.quizContent?.commentsFromConfiguration.userAnswer"
          id="slideContent?.quizContent?.commentsFromConfiguration.userAnswer" cols="1" rows="5" [disabled]="disabled"
          [required]="stepOne?.isFreeFormQuestionMandatory"></textarea>
      </div>
    </div>
  </div>
</div>
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { SafeType } from "../../../models/enum/safe-type.enum";
import { VIDEO_PLAYER_CONSTANT } from "src/app/create-course/data";
import { CourseService } from "src/app/services/course.service";
import { SlideService } from "src/app/services/slide.service";
import { DeviceDetectorService } from "ngx-device-detector";
import { QuizSlide } from "src/app/create-quiz/create-quiz.interfaces";

@Component({
  selector: "app-slide",
  templateUrl: "./slide.component.html",
  styleUrls: ["./slide.component.scss"],
})
export class SlideComponent implements OnInit, OnChanges {
  @Output() assetClicked: EventEmitter<any> = new EventEmitter();
  @Output() quizClicked: EventEmitter<any> = new EventEmitter();

  @Input() slideContent: any;
  @Input() stepOne: any;

  @Input() videoPlayOption: string = VIDEO_PLAYER_CONSTANT.AUTOMATIC;
  @Input() globalConfig!: any;
  @Input() openedInModal: boolean = false;
  @Input() buttonConfig!: any;
  @Input() isLoading: boolean = false;
  @Input() previewMode: boolean = false;
  @Input() disabled: boolean = false;
  @Input() courseCategory?: string = "";

  assetImageCount: number = 0;
  assetContainsVideo: boolean = false;
  assetContainsButton: boolean = false;
  assetContainsAudio: boolean = false;
  viewedButton: any = [];

  isIOS: any = false;
  // This video format remains same for any video type uploaded
  defaultVideoFormat: string = "video/mp4";
  safeType = SafeType;

  constructor(
    public courseService: CourseService,
    public slideService: SlideService,
    public deviceService: DeviceDetectorService,
  ) {}

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges): void {
    this.isLoading = true;
    this.assetContainsVideo =
      this.slideContent?.videos?.length > 0 ? true : false;
    this.assetContainsButton =
      this.slideContent?.buttons?.length > 0 ? true : false;
    this.assetContainsAudio =
      this.slideContent?.audios?.length > 0 ? true : false;
    this.assetImageCount = this.slideContent?.images?.length
      ? this.slideContent?.images?.length
      : 0;
    this.viewedButton = [];
    /** 
      return {
        category: this.course.category,
        tags: this.course?.slides?.[slideIndex]?.stepTwo?.tags,
        stepOne: this.course?.slides?.[slideIndex]?.stepOne,
        quizContent: this.quizContent
      }
     */
    if (this.slideContent?.category == "quiz") {
    }

    this.isIOS =
      this.deviceService.os == "iOS" || this.deviceService.os == "Mac"
        ? true
        : false;
    setTimeout(() => {
      this.isLoading = false;
    }, 100);
    // /* Device Info */
    // console.log("Device Info \n");
    // console.log(this.deviceService.getDeviceInfo());
  }

  onRadioChange(selectedAnswer: any, data: any) {
    data.forEach((answer: any) => {
      console.log((answer.user_answer = answer === selectedAnswer));
      answer.user_answer = answer === selectedAnswer;
    });
  }

  /* Getter Functions */

  get getAssetContainerClasses(): string {
    if (
      this.assetImageCount === 1 ||
      this.assetContainsButton ||
      (this.slideContent.editor && this.assetContainsVideo)
    ) {
      return `d-flex col-md-6 col-lg-6 col-sm-12 mb-3 flex-col justify-content-evenly ${
        this.isMobile && !this.assetContainsButton ? "h-50vh" : ""
      } ${this.isMobile && this.assetContainsButton ? "h-fit" : ""}`;
    } else if (this.assetImageCount === 2) {
      return `d-flex col-md-6 col-lg-6 col-sm-12 flex-col justify-content-around ${
        this.isMobile ? "h-auto" : ""
      }`;
    } else if (this.assetImageCount > 2) {
      return `d-flex col-md-12 ${this.isMobile ? "flex-col" : "flex-row"} ${
        this.assetContainsAudio && !this.isMobile ? "h-40 mt-n50" : "h-48"
      } justify-content-between ${this.isMobile ? "h-auto" : ""}`;
    } else if (!this.slideContent.editor && this.assetContainsVideo) {
      return `d-flex col-md-12 flex-row h-100 justify-content-between ${
        this.isMobile ? "h-auto" : ""
      }`;
    } else {
      return `d-flex col-md-12 flex-row h-100 justify-content-between ${
        this.isMobile ? "h-auto" : ""
      }`;
    }
  }

  get assetArray(): any[] {
    if (this.slideContent.images && this.slideContent.images.length > 0) {
      return this.slideContent.images;
    } else if (
      this.slideContent.videos &&
      this.slideContent.videos.length > 0
    ) {
      return this.slideContent.videos;
    } else if (
      this.slideContent.buttons &&
      this.slideContent.buttons.length > 0
    ) {
      return [this.slideContent.buttons];
    }
    return [];
  }

  get audioAsset(): any {
    if (this.slideContent.audios && this.slideContent.audios.length > 0) {
      return this.slideContent.audios[0].url;
    }
    return null;
  }

  get autoplayVideo(): boolean {
    return this.videoPlayOption == VIDEO_PLAYER_CONSTANT.AUTOMATIC
      ? true
      : false;
  }

  get isWidthGreaterThanHeight(): boolean {
    return window.innerWidth > window.innerHeight;
  }

  get isMobile(): boolean {
    return window.innerWidth < 767 ? true : false;
  }

  get isTablet(): boolean {
    return window.innerWidth < 1024 && window.innerWidth > 767 ? true : false;
  }
  /* */

  getVideoFileExtension(url: string): string {
    let videoExtension: any = url.split(/[#?]/)[0].split(".").pop()?.trim();
    if (videoExtension) {
      return `video/${url.split(/[#?]/)[0].split(".").pop()?.trim()}`;
    }
    return this.defaultVideoFormat;
  }

  clickAsset(i: number) {
    this.assetClicked.emit(i);
    if (this.assetContainsButton) {
      this.viewedButton.push(i);
    }
    if (this.assetContainsAudio) {
      this.slideService._stopAudioPlayer.next(true);
    }
  }

  get buttonHeightControl() {
    let characterMoreThen40 = this.slideContent.buttons.findIndex(
      (x: string) => x.length > 40,
    );
    let characterMoreThen20 = this.slideContent.buttons.findIndex(
      (x: string) => x.length > 20,
    );
    if (characterMoreThen40 > -1) {
      return "h-80";
    } else if (characterMoreThen20 > -1) {
      return "h-60";
    } else {
      return "h-60";
    }
  }

  get _globalConfig() {
    if (this.globalConfig) {
      if (typeof this.globalConfig == "string") {
        return JSON.parse(this.globalConfig);
      } else {
        return this.globalConfig;
      }
    } else {
      return null;
    }
  }

  checkMethod() {
    if (this.slideContent?.quizContent?.questionType) {
      this.quizClicked.emit(this.slideContent.quizContent);
    }
  }

  clickHotspot(name: string) {
    let a = this.slideContent?.hotspots.findIndex((x: any) => x.data == name);
    this.assetClicked.emit(a);
  }

  handleMissedCount(count: any) {
    this.assetClicked.emit({ index: 0 - count, missedClick: true });
  }
}

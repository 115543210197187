<div class="course-slide-content">
  <div class="card card-custom">
    <div class="card card-custom justify-content-center align-items-center" *ngIf="!showResult">
      <p class="congratulations-text"
        *ngIf="!quizAttempts?.length || !course?.globalConfigs?.correctIncorrectAnswerExist || quizAttempts?.length && !course?.globalConfigs?.isTherePassingGrade">
        {{'QUIZ_COMPLETED' | translate}}
      </p>
      <div
        *ngIf="quizAttempts?.length && course?.globalConfigs?.isTherePassingGrade && course.globalConfigs?.passingPercentage > 0">
        <h3>Results of This Attempt</h3>
        <br>
        <p>
          Grade Achieved: {{quizAttempts[quizAttempts?.length-1].Grade}} %
          <br>
          Passing Grade: {{this.course.globalConfigs?.passingPercentage}} %
          <br>
          Status: {{getStatus()}}
          <br>
        </p>
      </div>
      <br>
      <h3>Click OK to continue</h3>
      <br>
      <button (click)="viewResult()" title="View Result" nz-button nzType="primary" nzSize="large"
        *ngIf="course?.globalConfigs.displayAnswerCopy && quizAttempts?.length"
        class="button-container justify-content-center align-items-center">
        View My Answers
      </button>
    </div>

    <div class="result" *ngIf="showResult">
      <nz-tabset [(nzSelectedIndex)]="attemptIndex">
        <nz-tab *ngFor="let quizAttempt of quizAttempts; let idx = index" [nzTitle]="titleTemplate">
          <ng-template #titleTemplate>
            Attempt {{idx + 1}}
          </ng-template>
          <div class="right-align"
            *ngIf="course?.globalConfigs.correctIncorrectAnswerExist && course?.globalConfigs?.isTherePassingGrade && course.globalConfigs?.passingPercentage > 0">
            <b>Grade: </b>
            <span *ngIf="course?.globalConfigs.correctIncorrectAnswerExist">{{quizAttempts[attemptIndex]?.Grade |
              number:'1.0-0'}}{{'% '}}</span>
            <span
              *ngIf="course?.globalConfigs.correctIncorrectAnswerExist == false">{{quizAttempts[attemptIndex]?.ScoredPoints}}/{{quizAttempts[attemptIndex]?.TotalPoints}}</span>
            <span *ngIf="course?.globalConfigs?.isTherePassingGrade">
              {{quizAttempts[attemptIndex]?.Status}}</span> {{' '}}
          </div>
          <div *ngFor="let slide of quizAttempts[attemptIndex].QuizResult; let i = index">
            <div *ngIf="slide.questionType === 'multiChoice'">
              <p>{{ i + 1 }}.<span *ngIf="slide?.isQuestionMandatoryForQssp == false">(Optional)</span>
                {{ slide.mainQuestion.question}}
                <span class="correct-answer" *ngIf="slide?.answerStatus == 1">(Correct)</span>
                <span class="wrong-answer" *ngIf="slide?.answerStatus == 2">(Incorrect)</span>
              </p>
              <ul>
                <li *ngFor="let option of slide.multiChoice"
                  [ngClass]="{'correct-answer': option.correct_answer, 'wrong-answer': !option.correct_answer && option.user_answer}">
                  <i
                    [ngClass]="{'fa fa-check': option.correct_answer && option.user_answer, 'fas fa-times': !option.correct_answer && option.user_answer}"></i>
                  {{' '}}
                  {{ option.value }}
                </li>
              </ul>
            </div>
            <div *ngIf="slide.questionType === 'multiSelect'">
              <p>
                {{ i + 1 }}. <span *ngIf="slide?.isQuestionMandatoryForQssp == false">(Optional)</span>
                {{ slide.mainQuestion.question }}
                <span class="correct-answer" *ngIf="slide?.answerStatus == 1">(Correct)</span>
                <span class="wrong-answer" *ngIf="slide?.answerStatus == 2">(Incorrect)</span>
              </p>
              <ul>
                <li *ngFor="let option of slide.multiSelect"
                  [ngClass]="{'correct-answer': option.correct_answer, 'wrong-answer': !option.correct_answer && option.user_answer}">
                  <i
                    [ngClass]="{'fa fa-check': option.correct_answer && option.user_answer, 'fas fa-times': !option.correct_answer && option.user_answer}"></i>
                  {{' '}}
                  {{ option.label }}
                </li>
              </ul>
            </div>
            <div *ngIf="slide.questionType === 'horizontalScale'">
              <p>{{ i + 1 }}.<span *ngIf="slide?.isQuestionMandatoryForQssp == false">(Optional)</span> {{
                slide?.mainQuestion?.question }}</p>
              <div class="horizontal-scale-container">
                <ul class="horizontal-scale-answer-list">
                  <li class="horizontal-scale-answer-item" *ngFor="let answer of slide.horizontalScale; let b = index">
                    <label class="horizontal-scale-label">{{ answer.label || " " }}</label>
                    <label class="horizontal-scale-label" *ngIf="answer?.enableNumberLabels">{{ b + 1 }}</label>
                    <input type="radio" name="radio_group_{{i}}" [disabled]="true" [value]="answer.user_answer"
                      [checked]="answer.user_answer" class="horizontal-scale-custom-radio custom-radio">
                  </li>
                </ul>
              </div>
            </div>
            <div *ngIf="slide?.questionType === 'freeFormQuestion'">
              <p>{{ i + 1 }}. <span *ngIf="slide?.isQuestionMandatoryForQssp == false">(Optional)</span> {{
                slide?.mainQuestion?.question }}</p>
              <p>Answer: {{ slide.mainQuestion.userAnswer }}</p>
            </div>
            <div *ngIf="slide?.commentsFromConfiguration?.userAnswer">
              <p>{{ slide?.commentsFromConfiguration?.question }}</p>
              <p>Answer: {{ slide.commentsFromConfiguration.userAnswer }}</p>
            </div>
          </div>
        </nz-tab>
      </nz-tabset>
    </div>
  </div>
</div>
// translation.service.ts
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class TranslationService {
  private translations: { [key: string]: string } = {};

  constructor(private http: HttpClient) {}

  loadTranslations(language: string): Observable<{ [key: string]: string }> {
    const url = `assets/i18n/${language}.json`;
    return this.http.get<{ [key: string]: string }>(url).pipe(
      tap((loadedTranslations) => {
        this.translations = loadedTranslations;
      }),
    );
  }

  translate(key: string): string {
    return this.translations[key] || key;
  }
}

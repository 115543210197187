import { Component, EventEmitter, Output } from "@angular/core";
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";

import { Option } from "../../create-course.interfaces";
import {
  COMPLETION_NEXT_RULE,
  CompletionRule,
  LAYER_OPTIONS,
  COURSE_CONST,
  LAYER_ONE_AND_TWO_OPTIONS,
  LAYER_ONE_OPTIONS,
  SLIDE_TYPE_OPTIONS,
  PAGE_LAYOUT_OPTIONS,
  VIDEO_PLAYER_OPTIONS,
  VIDEO_PLAYER_CONSTANT,
  PAGE_LAYOUT_CONSTANT,
  LAYER_TWO_OPTIONS,
  BUTTON_COLOR_PROPERTIES,
  BUTTON_LAYOUT,
  BUTTON_LAYOUT_CONSTANT,
  BUTTON_APPEARANCE_CONSTANT,
  BUTTON_APPEARANCE,
} from "../../data";
import { CourseService } from "../../../services/course.service";
import { Utils } from "../../../core/utils";
import { Router } from "@angular/router";

@Component({
  selector: "app-step-one",
  templateUrl: "./step-one.component.html",
  styleUrls: ["./step-one.component.scss"],
})
export class StepOneComponent {
  validateForm!: UntypedFormGroup;
  currentLayerOptions!: Option[];
  slideTypeOptions!: Option[];
  pageLayoutOptions!: Option[];
  videoPlayerOptions!: Option[];
  buttonLayoutOptions!: Option[];
  buttonAppearancOption!: Option[];
  buttonConfigOptions!: Option[];
  completionRuleOptions: Option[] = CompletionRule;
  @Output() formSubmitted: EventEmitter<any> = new EventEmitter();
  styleObject: any;

  // Conditional Form Field Rendering
  showVideoPlayingSelector: boolean = false;
  showButtonConfiguration: boolean = false;

  constructor(
    private fb: UntypedFormBuilder,
    private courseService: CourseService,
    private router: Router,
  ) {}

  ngOnInit(): void {
    Utils.checkAuthorization(this.router);
    this.courseService.applyStyles();
    this.prepareStepOne();
  }

  prepareStepOne() {
    const savedData = this.courseService.retrieveCourseObject();
    // Setting Default Data For Selectors
    this.currentLayerOptions = LAYER_ONE_OPTIONS;
    this.slideTypeOptions = SLIDE_TYPE_OPTIONS;
    this.videoPlayerOptions = VIDEO_PLAYER_OPTIONS;
    this.buttonConfigOptions = BUTTON_COLOR_PROPERTIES;
    this.buttonLayoutOptions = BUTTON_LAYOUT;
    this.buttonAppearancOption = BUTTON_APPEARANCE;
    //
    const defaultLayer = savedData?.stepOne?.layer || COURSE_CONST.ONE;
    const defaultLayerOptions =
      savedData?.stepOne?.layerOptions || LAYER_OPTIONS.Text_Only;
    const enabledNext =
      savedData?.stepOne?.enabledNext || COMPLETION_NEXT_RULE.SLIDE_LOADED;
    const videoOption =
      savedData?.stepOne?.videoOption || VIDEO_PLAYER_CONSTANT.AUTOMATIC;
    const buttonBorderColorOption =
      savedData?.stepOne?.buttonBorderColor || BUTTON_COLOR_PROPERTIES[0].value;
    const buttonFillColorOption =
      savedData?.stepOne?.buttonFillColor || BUTTON_COLOR_PROPERTIES[0].value;
    const buttonTextColorOption =
      savedData?.stepOne?.buttonTextColor || BUTTON_COLOR_PROPERTIES[0].value;
    const buttonActiveColorOption =
      savedData?.stepOne?.buttonActiveColor || BUTTON_COLOR_PROPERTIES[1].value;
    const buttonActiveTextColorOption =
      savedData?.stepOne?.buttonActiveTextColor ||
      BUTTON_COLOR_PROPERTIES[1].value;
    const pageLayoutOption =
      savedData?.stepOne?.pageLayoutOption || PAGE_LAYOUT_CONSTANT.FULL_PAGE;
    const buttonLayoutOption =
      savedData?.stepOne?.buttonLayout || BUTTON_LAYOUT_CONSTANT.VERTICAL;
    const buttonAppearanceOption =
      savedData?.stepOne?.buttonAppearance || BUTTON_APPEARANCE_CONSTANT.ONLOAD;

    if (
      defaultLayerOptions == LAYER_ONE_OPTIONS[2].value ||
      defaultLayerOptions == LAYER_ONE_OPTIONS[3].value
    ) {
      this.showVideoPlayingSelector = true;
    }
    if (defaultLayerOptions == LAYER_ONE_AND_TWO_OPTIONS[1].value) {
      this.showButtonConfiguration = true;
    }
    if (defaultLayer == COURSE_CONST.TWO) {
      this.currentLayerOptions = LAYER_ONE_AND_TWO_OPTIONS;
      this.completionRuleOptions = CompletionRule;
    } else if (defaultLayer == COURSE_CONST.ONE) {
      this.completionRuleOptions = this.completionRuleOptions.slice(0, -1);
    }

    this.validateForm = this.fb.group({
      layer: [defaultLayer, [Validators.required]],
      layerOptions: [defaultLayerOptions, [Validators.required]],
      enabledNext: [enabledNext, [Validators.required]],
      videoOption: [videoOption, [Validators.required]],
      buttonBorderColor: [buttonBorderColorOption, [Validators.required]],
      buttonFillColor: [buttonFillColorOption, [Validators.required]],
      buttonTextColor: [buttonTextColorOption, [Validators.required]],
      buttonActiveColor: [buttonActiveColorOption, [Validators.required]],
      buttonLayout: [buttonLayoutOption, [Validators.required]],
      buttonActiveTextColor: [
        buttonActiveTextColorOption,
        [Validators.required],
      ],
      buttonAppearance: [buttonAppearanceOption, [Validators.required]],
      pageLayoutOption: [pageLayoutOption, [Validators.required]],
    });

    this.pageLayoutOptions = PAGE_LAYOUT_OPTIONS.filter(
      (x: Option) => x.value == this.pageLayoutControl?.value,
    );
  }

  submitForm(handleSaveOnly?: boolean): boolean {
    if (this.validateForm.valid || handleSaveOnly) {
      this.formSubmitted.emit(this.validateForm.value);
      this.courseService.updateCourseObject(
        this.validateForm.value,
        COURSE_CONST.STEP_ONE,
      );
    } else {
      Object.values(this.validateForm.controls).forEach((control) => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
    }
    return this.validateForm.valid;
  }

  onLayerSelect(selectedValue: any): void {
    if (selectedValue == COURSE_CONST.TWO) {
      this.currentLayerOptions = LAYER_ONE_AND_TWO_OPTIONS;
      this.completionRuleOptions = CompletionRule;
      this.validateForm
        ?.get(COURSE_CONST.LAYER_OPTIONS)
        ?.setValue(LAYER_OPTIONS.L1_L2_OP_1);
      this.validateForm
        ?.get(COURSE_CONST.ENABLED_NEXT)
        ?.setValue(COMPLETION_NEXT_RULE.LAYER_2_COMPLETED);
    } else {
      this.currentLayerOptions = LAYER_ONE_OPTIONS;
      this.completionRuleOptions = this.completionRuleOptions.slice(0, -1);
      this.validateForm
        ?.get(COURSE_CONST.LAYER_OPTIONS)
        ?.setValue(LAYER_OPTIONS.Text_Only);
      this.validateForm
        ?.get(COURSE_CONST.ENABLED_NEXT)
        ?.setValue(COMPLETION_NEXT_RULE.SLIDE_LOADED);
    }
  }

  onLayerOptionSelect(selectedValue: any): void {
    this.showVideoPlayingSelector = false;
    this.showButtonConfiguration = false;

    // Controlling Video Player Selector Rendering
    if (
      selectedValue == LAYER_ONE_OPTIONS[2].value ||
      selectedValue == LAYER_ONE_OPTIONS[3].value
    ) {
      this.showVideoPlayingSelector = true;
    }

    // Controlling Button Configuration Rendering
    if (selectedValue == LAYER_ONE_AND_TWO_OPTIONS[1].value) {
      this.showButtonConfiguration = true;
    }

    // Modifying Page Layout Field Options
    switch (selectedValue) {
      // Text OR Video Only
      case LAYER_ONE_OPTIONS[0].value:
        this.pageLayoutControl?.setValue(PAGE_LAYOUT_CONSTANT.FULL_PAGE);
        break;
      // Half Text & Half Image
      case LAYER_ONE_OPTIONS[1].value:
      case LAYER_ONE_AND_TWO_OPTIONS[0].value:
        this.pageLayoutControl?.setValue(
          PAGE_LAYOUT_CONSTANT.HALF_TEXT_HALF_IMAGE,
        );
        break;
      // Half Text & Half Video
      case LAYER_ONE_OPTIONS[2].value:
        this.pageLayoutControl?.setValue(
          PAGE_LAYOUT_CONSTANT.HALF_TEXT_HALF_VIDEO,
        );
        break;
      case LAYER_ONE_AND_TWO_OPTIONS[1].value:
        this.pageLayoutControl?.setValue(
          PAGE_LAYOUT_CONSTANT.HALF_TEXT_HALF_BUTTON,
        );
        break;
      default:
        this.pageLayoutControl?.setValue(PAGE_LAYOUT_CONSTANT.FULL_PAGE);
        break;
    }

    this.pageLayoutOptions = PAGE_LAYOUT_OPTIONS.filter(
      (x: Option) => x.value == this.pageLayoutControl?.value,
    );
  }

  /* Form Control Accessor */
  get pageLayoutControl(): any {
    return this.validateForm?.get(COURSE_CONST.PAGE_LAYOUT_OPTION);
  }

  get layerControl(): any {
    return this.validateForm?.get(COURSE_CONST.LAYER_OPTIONS);
  }
}

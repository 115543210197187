<div class="container audio-recorder-container">
  <div
    style="
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
    "
  >
    <a
      class="button"
      [ngClass]="{
        active: !isRecording && !blobUrl && !isCountDownEnable
      }"
      id="pushed"
      (click)="startRecording()"
      *ngIf="!isCountDownEnable && !blobUrl"
    >
      <i
        class="fas fa-microphone"
        [ngClass]="{
          'fa-microphone': !blobUrl,
          'fa-microphone-slash': !isRecording && blobUrl
        }"
      ></i>
    </a>
    <a
      class="button"
      *ngIf="!isCountDownEnable && !blobUrl"
      [ngClass]="{ 'push-stop': isRecording && !blobUrl && !isCountDownEnable }"
      id="active"
      (click)="stopRecording()"
    >
      <i class="fas fa-stop-circle"></i>
    </a>
    <a
      class="button"
      id="active"
      style="width: 70px"
      *ngIf="isRecording && !blobUrl && !isCountDownEnable"
    >
      {{ recordedTime }}
    </a>
  </div>
  <div
    style="
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
    "
  >
    <a class="title-button" id="active" *ngIf="isCountDownEnable">
      Recording will start in {{ recordingStartTimer }} seconds :
    </a>
    <a
      class="button pushed"
      id="active"
      style="width: 70px"
      *ngIf="isCountDownEnable"
    >
      {{ countDown }}
    </a>
  </div>
  <div *ngIf="!isRecording && blobUrl">
    <audio class="audio-player-width" controls>
      <source [src]="blobUrl" type="audio/webm" />
    </audio>
  </div>
  <div
    style="
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
    "
    *ngIf="!isRecording && blobUrl"
  >
    <a
      class="button stop"
      (click)="clearRecordedData()"
      title="Delete this recording"
    >
      <i class="fas fa-trash-alt"></i>
    </a>
    <a
      class="button check"
      (click)="saveRecording()"
      title="Save this recording"
    >
      <i class="fas fa-check"></i>
    </a>
  </div>
</div>

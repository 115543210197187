import { Component } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { NzMessageService } from "ng-zorro-antd/message";
import { LocalStorageUtils, Utils } from "../../../core/utils";
import { CourseService } from "../../../services/course.service";
import { CATEGORIES, COURSE_CONST } from "../../../create-course/data";
import { CourseApiService } from "../../../services/course.api.service";
import {
  CourseRequest,
  CourseResponse,
} from "../../../models/course-interface";
import { SpinnerService } from "src/app/services/spinner.service";
import { TranslationService } from "src/app/services/translation.service";
import { NzModalService } from "ng-zorro-antd/modal";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import {
  ATTEMPT_DATA,
  MAXIMUM_RETRY,
  QSSP_COMPLETE_STATUS_DATA,
  VALID_ATTEMPT_DATA,
} from "../../data";

@Component({
  selector: "app-quiz-home",
  templateUrl: "./quiz-home.component.html",
  styleUrls: ["./quiz-home.component.scss"],
})
export class QuizHomeComponent {
  courseId: number = 0;
  foundedCourse: any;
  courseCreateResponse$: any;
  course: any;
  mode!: string;
  eventName: string = "";
  enableLogoandtitle: boolean = false;
  enableAudioAutoplay: boolean = false;
  categoryName!: string;
  editDisabled!: boolean;
  courses: any[] = [];
  foundCourse = {};
  categories: string[] = CATEGORIES;
  quizGlobalConfiguration!: FormGroup;
  attemptData = ATTEMPT_DATA;
  validAttemptData = VALID_ATTEMPT_DATA;
  qsspCompleteStatusData = QSSP_COMPLETE_STATUS_DATA;

  constructor(
    private router: Router,
    private message: NzMessageService,
    private activateRoute: ActivatedRoute,
    private courseService: CourseService,
    private courseApiService: CourseApiService,
    private spinner: SpinnerService,
    private modalService: NzModalService,
    public translationService: TranslationService,
    public formBuilder: FormBuilder,
  ) {}

  ngOnInit(): void {
    Utils.checkAuthorization(this.router);
    this.courseService.applyStyles();
    this.courseService.deleteCourseBuilderSteps();
    this.initializeFormControl();
    this.handleCategories();
    this.editDisabled = this.courseService.isEditDisabled();
    this.getCourseMode();
  }

  initializeFormControl() {
    this.quizGlobalConfiguration = this.formBuilder.group({
      enableLogoandtitle: [false],
      enableBackButton: [false],
      canUserContinue: [true],
      correctIncorrectAnswerExist: [true],
      isTherePassingGrade: [false],
      displayAnswerCopy: [false],
      attempt: ["3"],
      validAttemptCount: ["1"],
      qsspInvalidation: ["1"],
      passingPercentage: [0],
      fileName: ["", [Validators.required]],
      progressDetailsEnabled: [true],
      quizMaximumAttempt: [100000],
    });
  }

  handleCategories() {
    this.categoryName = "quiz";
  }

  getCourseMode() {
    this.courseId = Number(
      this.courseService.getQueryParamByName(this.activateRoute, "courseId") ||
        0,
    );
    if (this.courseId > 0) {
      this.spinner.show();
      this.courseApiService.getCourse(this.courseId).subscribe(
        (response: any) => {
          this.spinner.hide();
          this.course = response.data.attributes;
          this.mode = COURSE_CONST.UPDATE_MODE;
          this.foundedCourse = this.course;
          this.eventName = this.course.title;
          this.quizGlobalConfiguration.patchValue(
            this.course.globalConfigs &&
              typeof this.course.globalConfigs == "string"
              ? JSON.parse(this.course.globalConfigs)
              : this.course.globalConfigs,
          );
        },
        (error: any) => {
          this.spinner.hide();
        },
      );
    }
  }

  handleExit(): void {
    if (this.mode == COURSE_CONST.CREATE_MODE) {
      this.goBackToHome();
    } else {
      this.router.navigate(["/view-courses"], {
        queryParams: { category: this.categoryName },
      });
    }
  }

  showMessageDialog(message: string) {
    this.modalService.create({
      nzTitle: this.translationService.translate("INFORMATION"),
      nzContent: message,
      nzOkText: "OK",
      nzClosable: false,
      nzCancelText: null, // or nzCancelText: ''
      nzOnOk: () => {},
    });
  }

  handleSave(): void {
    if (this.verifyEventName()) return;
    if (this.mode == COURSE_CONST.CREATE_MODE) {
      this.courseId = 0;
      this.spinner.show();
      this.courseService.saveGloalConfigs(this.quizGlobalConfiguration.value);
      this.courseCreateResponse$ = this.courseApiService
        .createCourse(this.getCourseRequest())
        .subscribe(
          (response: any) => {
            this.spinner.hide();
          },
          (error: any) => {
            this.spinner.hide();
          },
        );
    } else {
      this.spinner.show();
      const courseRequest: CourseRequest = {
        data: {
          title: this.eventName.trim(),
          globalConfigs: this.getGlobalConfig(),
        },
      };
      this.courseService.saveGloalConfigs(this.quizGlobalConfiguration.value);
      this.courseApiService
        .updateCourse(this.courseId, courseRequest)
        .subscribe(
          (response: any) => {
            this.spinner.hide();
          },
          (error: any) => {
            this.spinner.hide();
          },
        );
    }
  }

  verifyEventName() {
    if (
      this.mode === COURSE_CONST.CREATE_MODE ||
      this.mode === COURSE_CONST.UPDATE_MODE
    ) {
      if (this.eventName.trim().length < 1) {
        this.showMessageDialog(
          this.translationService.translate("PLEASE_ENTER_NAME_LABEL"),
        );
        return true;
      }
      if (this.quizGlobalConfiguration?.value?.fileName?.trim()?.length < 1) {
        this.showMessageDialog(
          this.translationService.translate("PLEASE_ENTER_FILE_NAME_LABEL"),
        );
        return true;
      }
    }

    return false;
  }

  handleCreateNewCourse(): void {
    this.mode = "CREATE";
  }

  handleNavigation(): void {
    if (this.verifyEventName()) return;
    if (this.mode == COURSE_CONST.UPDATE_MODE) {
      this.updateCourse();
    } else {
      this.createCourse();
    }
  }

  createCourse() {
    this.spinner.show();
    this.courseService.saveGloalConfigs(this.quizGlobalConfiguration.value);
    this.courseApiService.createCourse(this.getCourseRequest()).subscribe(
      (response: CourseResponse) => {
        this.navigateToEditQuiz(response?.id);
        this.spinner.hide();
      },
      (error: any) => {
        this.spinner.hide();
      },
    );
  }

  updateCourse() {
    const courseRequest: CourseRequest = {
      data: {
        title: this.eventName,
        globalConfigs: this.getGlobalConfig(),
        status: COURSE_CONST.NOT_COMPLETED,
      },
    };
    this.spinner.show();
    this.courseService.saveGloalConfigs(this.quizGlobalConfiguration.value);
    this.courseApiService.updateCourse(this.courseId, courseRequest).subscribe(
      (response: any) => {
        this.navigateToEditQuiz(this.courseId);
        this.spinner.hide();
      },
      (error: any) => {
        this.spinner.hide();
      },
    );
  }

  navigateToEditQuiz(courseId: any) {
    this.router.navigate([`/edit-${this.categoryName}/${courseId}`], {
      queryParams: { step: 2, slide: 1 },
    });
  }

  getGlobalConfig() {
    return JSON.stringify(this.quizGlobalConfiguration.value);
  }

  getCourseRequest() {
    const courseRequest: CourseRequest = {
      data: {
        title: this.eventName.trim(),
        globalConfigs: this.getGlobalConfig(),
        category: "quiz",
        totalSlideCount: 0,
        version: 1,
        duplicated: false,
        slides: "[]",
        status: "Not-Completed",
        currentVersion: false,
        courseVersionIdentifier: "",
        user: LocalStorageUtils.getUser()?.id.toString(),
        client: LocalStorageUtils.getClient()?.id.toString(),
      },
    };
    return courseRequest;
  }

  goBack(): void {
    this.mode = "";
  }

  goBackToHome(): void {
    this.router.navigate(["/"]);
  }

  /* Get Methods */
  get validateUpdateOrCreate() {
    return this.mode === "CREATE" || this.mode === "UPDATE";
  }

  get notMode() {
    return !this.mode;
  }

  get _correctIncorrectAnswerExist() {
    return this.quizGlobalConfiguration.get("correctIncorrectAnswerExist")
      ?.value;
  }

  get _displayAnswerCopy() {
    return this.quizGlobalConfiguration.get("displayAnswerCopy")?.value;
  }

  get _isTherePassingGrade() {
    return this.quizGlobalConfiguration.get("isTherePassingGrade")?.value;
  }
}

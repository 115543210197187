import { Option } from "../create-course/create-course.interfaces";

export const ATTEMPT_DATA: Option[] = [
  {
    label: "All attempts",
    value: "1",
  },
  {
    label: "All 'valid attempts'",
    value: "2",
  },
  {
    label: "Only the final valid attempt",
    value: "3",
  },
];

export const VALID_ATTEMPT_DATA: Option[] = [
  {
    label: 'QQSP is "Complete"',
    value: "1",
  },
];

export const QSSP_COMPLETE_STATUS_DATA: Option[] = [
  {
    label: "All QQSP mandatory questions have been completed?",
    value: "1",
  },
  // {
  //     label: 'QQSP is started',
  //     value: "2"
  // }
];

export const QUESTION_TYPE_DATA: Option[] = [
  {
    label: "Multiple-choice",
    value: "1",
  },
  {
    label: "MultiSelect",
    value: "2",
  },
  {
    label: "Question and free form answer in open text field",
    value: "3",
  },
  {
    label: "Question + horizontal scale",
    value: "4",
  },
];

/* Question Page Configuration */

export const QUESTION_TYPE: Option[] = [
  {
    label: "Multiple-choice",
    value: "1",
  },
  {
    label: "MultiSelect",
    value: "2",
  },
  {
    label: "Question and free form answer in open text field",
    value: "3",
  },
  {
    label: "Question + horizontal scale",
    value: "4",
  },
];

export const ACTION_WHEN_ANSWERED_CORRECTLY: Option[] = [
  {
    label: "Proceed to next question",
    value: "1",
  },
  {
    label: 'Show feedback popup. Proceed to next on click "OK"',
    value: "2",
  },
];

export const ACTION_WHEN_ANSWERED_INCORRECTLY: Option[] = [
  {
    label: "Proceed to next question",
    value: "1",
  },
  {
    label: 'Show feedback popup. Proceed to next on click "OK"',
    value: "2",
  },
  {
    label: 'Show feedback popup. Allow to re-try on click "OK"',
    value: "3",
  },
];

export const MAXIMUM_RETRY: Option[] = [
  // {
  //     label: '1',
  //     value: '1'
  // },
  // {
  //     label: '2',
  //     value: '2'
  // },
  {
    label: "Unlimited",
    value: "3",
  },
];

export const QUIZ_CONST = {
  ONE: "1",
  TWO: "2",
  ID: "id",
  STEP: "step",
  EVENT_NAME: "event-name",
  CATEGORY_NAME: "category-name",
  STEP_ONE_DATA: "stepOne",
  UPDATE_MODE: "UPDATE",
  CREATE_MODE: "CREATE",
  COMPLETED: "Completed",
  NOT_COMPLETED: "Not-Completed",
  STEP_ONE: "stepOne",
  ENABLED_NEXT: "enabledNext",
  STEP_TWO: "stepTwo",
  STEP_THREE: "stepThree",
  MODAL_FORMS: "modalForms",
  PREVIEW_MODE: "previewMode",
  TRUE: "true",
  QUESTION_TITLE: "questionTitle",
  QUESTION_TEXT: "questionText",
  QUESTION: "question",
  TAGS: "tags",
  FREE_FORM_QUESTION: "freeFormQuestion",
  POSSIBLE_ANSWER: "possibleAnswer",
  ANSWER_LABEL: "answerLabels",
  ANSWERS: "answers",
  CORRECT_ANSWER: "correctAnswer",
  HORIZONTAL_LABEL: "horizontalLabels",
  // POINT_SYSTEM: 'pointSystem',
  // OPTIONS: 'options'
};

<div class="headings">
  <h4 nz-typography>
    {{ "LAYER1_QUESTION_TITLE" | translate }}
    <span class="italic" nz-typography>*{{ "MANDATORY" | translate }}</span>
  </h4>
</div>

<form
  nz-form
  [nzLayout]="'vertical'"
  [formGroup]="validateForm"
  (ngSubmit)="submitForm()"
>
  <app-quiz-content-editor
    [questionType]="questionType"
    [enableFreeFormAnswer]="enableFreeFormAnswer"
    [parentForm]="validateForm"
    [componentName]="courseConst.STEP_TWO"
    [isFormSubmit]="isFormSubmit"
    (formSubmitted)="submitForm($event)"
  >
  </app-quiz-content-editor>
</form>

import { Pipe, PipeTransform } from "@angular/core";
import {
  DomSanitizer,
  SafeHtml,
  SafeResourceUrl,
  SafeScript,
  SafeStyle,
  SafeUrl,
} from "@angular/platform-browser";
import { SafeType } from "src/app/models/enum/safe-type.enum";

@Pipe({
  name: "sanitize",
})
export class DomSanitizerPipe implements PipeTransform {
  constructor(protected sanitizer: DomSanitizer) {}

  public transform(
    value: any,
    type: SafeType,
  ): SafeHtml | SafeStyle | SafeScript | SafeUrl | SafeResourceUrl {
    switch (type) {
      case SafeType.HTML:
        return this.sanitizer.bypassSecurityTrustHtml(value);
      case SafeType.STYLE:
        return this.sanitizer.bypassSecurityTrustStyle(value);
      case SafeType.SCRIPT:
        return this.sanitizer.bypassSecurityTrustScript(value);
      case SafeType.URL:
        return this.sanitizer.bypassSecurityTrustUrl(value);
      case SafeType.RESOURCE_URL:
        return this.sanitizer.bypassSecurityTrustResourceUrl(value);
      default:
        throw new Error(`Invalid safe type specified: ${type}`);
    }
  }
}

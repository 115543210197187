import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { CourseHomeComponent } from "./create-course/components/course-home/course-home.component";
import { CreateCourseComponent } from "./create-course/create-course.component";
import { ViewCoursesComponent } from "./view-courses/view-courses.component";
import { ViewCourseComponent } from "./view-course/view-course.component";
import { CategoriesComponent } from "./categories/categories.component";
import { NotFoundComponent } from "./error-handler/not-found/not-found.component";
import { StartComponent } from "./start/start.component";
import { ForbiddenComponent } from "./error-handler/forbidden/forbidden.component";
import { UnAuthorizedComponent } from "./error-handler/un-authorized/un-authorized.component";
import { QuizHomeComponent } from "./create-quiz/components/quiz-home/quiz-home.component";
import { CreateQuizComponent } from "./create-quiz/create-quiz.component";

const routes: Routes = [
  {
    component: CategoriesComponent,
    path: "",
  },

  {
    component: StartComponent,
    path: "start",
  },
  {
    component: CreateCourseComponent,
    path: "create-course",
  },
  {
    component: NotFoundComponent,
    path: "not-found",
  },
  {
    component: ForbiddenComponent,
    path: "forbidden",
  },
  {
    component: UnAuthorizedComponent,
    path: "unauthorized",
  },
  {
    component: ViewCoursesComponent,
    path: "view-courses",
  },
  {
    component: ViewCourseComponent,
    path: "view-courses/:id",
  },
  {
    component: CreateCourseComponent,
    path: "edit-edeCourseContent/:id",
  },
  {
    component: CourseHomeComponent,
    path: "edeCourseContent",
  },
  {
    component: QuizHomeComponent,
    path: "quiz",
  },
  {
    component: CreateQuizComponent,
    path: "edit-quiz/:id",
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      anchorScrolling: "enabled",
      scrollPositionRestoration: "enabled",
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}

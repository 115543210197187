<div class="headings">
    <h4 nz-typography>
      <span class="font-size-20" nz-typography
        >- {{ "LAYER2_TEXT_IMAGE" | translate }}</span
      >
      <span class="italic" nz-typography>*{{ "MANDATORY" | translate }}</span>
    </h4>
  </div>

  
  <nz-tabset [(nzSelectedIndex)]="activeTab">
    <nz-tab *ngFor="let panel of panels; let i = index" [nzTitle]="titleTemplate">
      <ng-template #titleTemplate>
        {{ getIconBasedOnFormStatus(i) }} {{ panel.name }}
      </ng-template>
      <form
        *ngIf="getFormGroupAtIndex(i)"
        nz-form
        [nzLayout]="'vertical'"
        [formGroup]="getFormGroupAtIndex(i)"
      >
        
        <nz-form-item>
          <nz-form-label nzRequired nzFor="layer2Options">{{
            "LAYER2_OPTIONS" | translate
          }}</nz-form-label>
          <nz-form-control
            nzErrorTip="{{ 'SELECT_LAYER_OPTION_PLACEHOLDER' | translate }}"
          >
            <nz-select
              id="layerOptions"
              formControlName="layer2Options"
              nzPlaceHolder="{{ 'SELECT_PLACEHOLDER' | translate }}"
            >
              <nz-option
                *ngFor="let option of layerTwoOptions"
                [nzValue]="option.value"
                [nzLabel]="option.label"
                [nzDisabled]="option.disabled"
              ></nz-option>
            </nz-select>
          </nz-form-control>
        </nz-form-item>
        <app-content-editor
          *ngIf="fileList.length > 0"
          [parentForm]="getFormGroupAtIndex(i)"
          [selectedOption]="getFormGroupAtIndex(i).get('layer2Options')"
          [fileList]="fileList[i]"
          [componentName]="courseConst.STEP_THREE"
          [audioFileList]="audioFileList[i]"
          [disableUploadButton]="disableUploadButton"
          (formSubmitted)="submitForm($event)"
          [indexOfActiveContentEditor]="activeTab"
          [indexOfContentEditor]="i"
          [courseCategory]="'quiz'"
        >
        </app-content-editor>
      </form>
    </nz-tab>
  </nz-tabset>
  
import { Component, Input, OnInit } from "@angular/core";
import { Utils } from "src/app/core/utils";
import { CourseService } from "src/app/services/course.service";
import { Router } from "@angular/router";

@Component({
  selector: "app-congratulation",
  templateUrl: "./congratulation.component.html",
  styleUrls: ["./congratulation.component.scss"],
})
export class CongratulationComponent implements OnInit {
  styleObject: any;
  @Input() course: any = new Object();
  @Input() quizAttempts: any = [];

  attemptIndex = 0;

  showResult: boolean = false;
  constructor(
    private courseService: CourseService,
    private router: Router,
  ) {}

  ngOnInit(): void {
    Utils.checkViewerAuthorization(this.router);
    this.courseService.applyStyles();
  }

  viewResult() {
    this.attemptIndex = this.quizAttempts?.length - 1;
    this.showResult = true;
  }

  updateAttemptIndex(idx: number) {
    this.attemptIndex = idx;
  }
  getSelectedHorizontalScaleValue(scaleOptions: any[]): string {
    const selectedOption = scaleOptions.find((option) => option.user_answer);
    return selectedOption ? selectedOption.value : "No selection";
  }

  getStatus(): string {
    if (this.quizAttempts[this.quizAttempts?.length - 1].Status == "Fail")
      return "Failed";
    else if (this.quizAttempts[this.quizAttempts?.length - 1].Status == "Pass")
      return "Passed";

    return "";
  }
}

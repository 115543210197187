import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { CourseService } from "src/app/services/course.service";
import { SlideService } from "src/app/services/slide.service";
import { DeviceDetectorService } from "ngx-device-detector";
import videojs from "video.js";

@Component({
  selector: "app-audio-player",
  templateUrl: "./audio-player.component.html",
  styleUrls: ["./audio-player.component.scss"],
})
export class AudioPlayerComponent implements OnInit, AfterViewInit, OnChanges {
  @ViewChild("AudioPlayer") audioPlayer!: ElementRef;
  @ViewChild("ModalAudioPlayer") modalAudioPlayer!: ElementRef;
  @ViewChild("ProgressInput") progressInput!: ElementRef;

  isPlaying: boolean = false;
  deviceInfo: any = null;
  player: any;
  @Input() audioSource!: string;
  @Input() audioFile!: any;
  @Input() audioAutoplay: boolean = false;
  @Input() openedInModal: boolean = false;
  progressInterval: any = 0;

  constructor(
    public courseService: CourseService,
    public slideService: SlideService,
    public deviceService: DeviceDetectorService,
  ) {}

  ngOnInit(): void {
    this.slideService._stopAudioPlayer.subscribe((state) => {
      const audio = this.audioPlayer?.nativeElement;
      audio?.pause();
    });
    this.slideService._stopAudioPlayerLayer2.subscribe((state) => {
      const audio = this.modalAudioPlayer?.nativeElement;
      audio?.pause();
    });
  }

  ngAfterViewInit(): void {
    const audio = this.openedInModal
      ? this.modalAudioPlayer?.nativeElement
      : this.audioPlayer?.nativeElement;

    audio?.addEventListener("play", () => {
      this.isPlaying = true;
      this.stopAnyVideoPlayer();
    });
    audio?.addEventListener("pause", () => {
      this.isPlaying = false;
      clearInterval(this.progressInterval);
    });
    audio?.addEventListener("ended", () => {
      this.isPlaying = false;
      clearInterval(this.progressInterval);
    });

    if (this.audioAutoplay) {
      this.togglePlay();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.audioAutoplay) {
      this.audioAutoplay = true;
    }
  }

  togglePlay(): void {
    const audio = this.openedInModal
      ? this.modalAudioPlayer?.nativeElement
      : this.audioPlayer?.nativeElement;
    this.isPlaying = !this.isPlaying;
    if (audio && audio.paused) {
      audio.play();
    } else {
      audio?.pause();
    }
  }

  repeat(): void {
    const audio = this.openedInModal
      ? this.modalAudioPlayer?.nativeElement
      : this.audioPlayer?.nativeElement;
    audio.currentTime = 0;
  }

  forward(): void {
    const audio = this.openedInModal
      ? this.modalAudioPlayer?.nativeElement
      : this.audioPlayer?.nativeElement;
    audio.currentTime += 5;
  }

  backward(): void {
    const audio = this.openedInModal
      ? this.modalAudioPlayer?.nativeElement
      : this.audioPlayer?.nativeElement;
    audio.currentTime -= 5;
  }

  seekTo(value: any): void {
    const audio = this.openedInModal
      ? this.modalAudioPlayer?.nativeElement
      : this.audioPlayer?.nativeElement;
    const seekTime = (parseFloat(value) / 100) * audio.duration;
    audio.currentTime = seekTime;
  }

  stopAnyVideoPlayer() {
    this.slideService._stopVideoPlayer.next(true);
  }

  getVideoFileExtension(url: string): string {
    let videoExtension: any = url.split(/[#?]/)[0].split(".").pop()?.trim();
    if (videoExtension) {
      return `video/${url.split(/[#?]/)[0].split(".").pop()?.trim()}`;
    }
    return "mp3";
  }

  /* */
  get _primaryBgColor() {
    return this.courseService.getDefaultThemeColor("primaryColor");
  }

  get _primaryTextColor() {
    return this.courseService.getDefaultThemeColor("primaryTextColor");
  }

  get _secondaryBgColor() {
    return this.courseService.getDefaultThemeColor("secondaryColor");
  }

  get _secondaryTextColor() {
    return this.courseService.getDefaultThemeColor("secondaryTextColor");
  }
}

import { Component, EventEmitter, Output } from "@angular/core";
import { CourseService } from "../../../services/course.service";
import { TranslationService } from "src/app/services/translation.service";

import {
  UntypedFormBuilder,
  UntypedFormGroup,
  FormArray,
  Validators,
  FormGroup,
  ValidatorFn,
} from "@angular/forms";
import { Option } from "../../create-quiz.interfaces";
import {
  LAYER_OPTIONS,
  COURSE_CONST,
  LAYER_ONE_OPTIONS,
  VIDEO_PLAYER_CONSTANT,
  VIDEO_PLAYER_OPTIONS,
} from "../../../create-course/data";

import { Utils } from "../../../core/utils";
import { Router } from "@angular/router";

import { SupportedAsset } from "src/app/models/enum/supported-asset.enum";

@Component({
  selector: "app-quiz-step-three",
  templateUrl: "./quiz-step-three.component.html",
  styleUrls: ["./quiz-step-three.component.scss"],
})
export class QuizStepThreeComponent {
  @Output() formSubmitted: EventEmitter<any> = new EventEmitter();
  validateForm!: UntypedFormGroup;
  validateFormTitle!: UntypedFormGroup;
  courseBuilderSteps: any;

  layerTwoOptions!: Option[];
  fileList: any[] = [];
  audioFileList: any[] = [];
  panels: any[] = [];
  styleObject: any;
  supportedAsset: any = SupportedAsset;
  submittingForm: boolean = false;
  disableUploadButton: boolean = false;
  showVideoPlayingSelector: boolean = false;
  videoPlayerOptions: any = [];
  activeTab: number = 0;
  courseConst = COURSE_CONST;

  constructor(
    private fb: UntypedFormBuilder,
    public courseService: CourseService,
    private router: Router,
    public translationService: TranslationService,
  ) {}

  ngOnInit() {
    Utils.checkAuthorization(this.router);
    this.courseService.applyStyles();
    this.setCourseBuilderSteps();
    this.prepareImages();
    this.initFormArray();
    this.videoPlayerOptions = VIDEO_PLAYER_OPTIONS;
  }

  setCourseBuilderSteps() {
    this.courseBuilderSteps = this.courseService.retrieveCourseObject() || [];
  }

  prepareImages() {
    const previewData: any[] =
      this.courseBuilderSteps?.stepTwo?.images ||
      this.courseBuilderSteps?.stepTwo?.buttons ||
      [];
    let opts = LAYER_ONE_OPTIONS;

    opts.forEach((option, index) => {
      option.disabled = index != 0;
    });

    this.layerTwoOptions = opts;

    const enableAnswerPanel =
      +this.courseBuilderSteps?.stepOne?.questionType === 1 ||
      +this.courseBuilderSteps?.stepOne?.questionType === 2;
    if (enableAnswerPanel) {
      const incorrectAnswerPanel = enableAnswerPanel
        ? {
            active: true,
            type: SupportedAsset.IMAGE,
            name: "Incorrect Answer",
            imageUrl: "",
          }
        : null;

      const correctAnswerPanel = enableAnswerPanel
        ? {
            active: true,
            type: SupportedAsset.IMAGE,
            name: "Correct Answer",
            imageUrl: "",
          }
        : null;

      if (incorrectAnswerPanel !== null) {
        this.panels.push(incorrectAnswerPanel);
      }

      if (correctAnswerPanel !== null) {
        this.panels.push(correctAnswerPanel);
      }
    } else if (this.courseBuilderSteps?.stepTwo?.buttons) {
      previewData.forEach((button, index) => {
        this.panels.push({
          active: true,
          type: SupportedAsset.BUTTON,
          name: `Asset-${index + 1}`,
          buttonConfig: {
            name: button,
            borderColor: this.courseBuilderSteps?.stepOne?.buttonBorderColor,
            fillColor: this.courseBuilderSteps?.stepOne?.buttonFillColor,
            textColor: this.courseBuilderSteps?.stepOne?.buttonTextColor,
          },
        });
      });
    }

    this.validateForm = this.fb.group({
      modalForms: this.fb.array([]),
    });
  }

  getFormGroupAtIndex(index: number): FormGroup {
    return (this.validateForm?.get(COURSE_CONST.MODAL_FORMS) as FormArray).at(
      index,
    ) as FormGroup;
  }

  private initFormArray() {
    const modalForms: any = this.validateForm?.get(
      COURSE_CONST.MODAL_FORMS,
    ) as FormArray;

    this.panels.forEach((panel, index) => {
      const formGroup = this.fb.group({
        slideTitle: [
          this.courseBuilderSteps?.stepThree?.modalForms[index]?.slideTitle ||
            "",
          [],
        ],
        editor: [
          this.courseBuilderSteps?.stepThree?.modalForms[index]?.editor || "",
          [],
        ],
        images: this.fb.array([], []),
        videos: this.fb.array([], []),
        audios: this.fb.array([], []),
        assetOptions: this.fb.group({
          videoOption: [
            this.courseBuilderSteps?.stepThree?.modalForms[index]?.assetOptions
              ?.videoOption || VIDEO_PLAYER_CONSTANT.AUTOMATIC,
            [],
          ],
        }),
        layer2Options: [
          this.courseBuilderSteps?.stepThree?.modalForms[index]
            ?.layer2Options || LAYER_OPTIONS.Text_Only,
          [Validators.required],
        ],
      });
      this.setFormValidator(formGroup);
      // If Layer2OptionChanges, Update form Value Again
      formGroup?.get("layer2Options")?.valueChanges.subscribe((value: any) => {
        this.resetingFormValuesOnLayerOptionChange(formGroup, index);
        this.setFormValidator(formGroup);
      });
      //
      modalForms.push(formGroup);
      this.setFilesContent(index);
    });
  }

  getIconBasedOnFormStatus(index: number) {
    if (this.getFormGroupAtIndex(index).valid) {
      return "✅";
    } else {
      return "❌";
    }
  }

  getToolTipContent(index: number) {
    if (this.getFormGroupAtIndex(index).valid) {
      return this.translationService.translate("VALID_FORM");
    } else {
      return this.translationService.translate("INVALID_FORM");
    }
  }

  setFilesContent(index: any) {
    const selectedTabForm: any = (
      this.validateForm?.get(COURSE_CONST.MODAL_FORMS) as FormArray
    ).at(index) as FormGroup;

    let storedImages: string[] =
      this.courseBuilderSteps?.stepThree?.modalForms[index]?.images || [];
    let storedVideos: string[] =
      this.courseBuilderSteps?.stepThree?.modalForms[index]?.videos || [];
    let storedAudios: string[] =
      this.courseBuilderSteps?.stepThree?.modalForms[index]?.audios || [];
    let fileList: any = [];
    let audioFileList: any = [];
    let assetType: any;
    let audioAssetType: any;

    if (storedImages.length > 0) {
      assetType = SupportedAsset.IMAGE;
    } else if (storedVideos.length > 0) {
      assetType = SupportedAsset.VIDEO;
    }

    if (storedAudios.length > 0) {
      audioAssetType = SupportedAsset.AUDIO;
    }

    const assetArray = selectedTabForm?.get(
      assetType == SupportedAsset.IMAGE
        ? COURSE_CONST.IMAGES
        : COURSE_CONST.VIDEOS,
    ) as FormArray;

    const audioAssetArray = selectedTabForm?.get(
      COURSE_CONST.AUDIOS,
    ) as FormArray;

    if (assetType) {
      let storedAsset =
        assetType == SupportedAsset.IMAGE ? storedImages : storedVideos;
      storedAsset.forEach((file: any, index) => {
        assetArray.push(this.fb.control(file));
        fileList.push({
          uid: index.toString(),
          id: file.id,
          name: file.name ? file.name : `${assetType}-${index}`,
          status: "done",
          url: file.url,
          thumbUrl: file.thumbUrl ? file.thumbUrl : file.url,
        });
      });
    }

    if (audioAssetType) {
      storedAudios.forEach((file: any, index) => {
        audioAssetArray.push(this.fb.control(file));
        audioFileList.push({
          uid: index.toString(),
          id: file.id,
          name: file.name ? file.name : `${assetType}-${index}`,
          status: "done",
          url: file.url,
          thumbUrl: "",
        });
      });
    }

    this.fileList[index] = fileList;
    this.audioFileList[index] = audioFileList;
  }

  submitForm(handleSaveOnly?: boolean): boolean {
    this.submittingForm = true;
    if (this.validateForm.valid || handleSaveOnly) {
      const stepThree = this.validateForm.value;
      this.courseService.updateCourseObject(
        this.validateForm.value,
        COURSE_CONST.STEP_THREE,
      );
      // Emit the form data for any parent component or service that might be listening
      this.formSubmitted.emit(stepThree);
    } else {
      this.resetAssetForm();
    }
    this.submittingForm = false;
    return this.validateForm.valid;
  }

  resetAssetForm() {
    const modalFormsArray = this.validateForm?.get(
      COURSE_CONST.MODAL_FORMS,
    ) as FormArray;
    for (let i = 0; i < modalFormsArray.length; i++) {
      const formGroup = modalFormsArray.at(i) as FormGroup;
      for (const controlName in formGroup.controls) {
        if (formGroup.controls.hasOwnProperty(controlName)) {
          formGroup?.get(controlName)?.markAsTouched();
          formGroup?.get(controlName)?.updateValueAndValidity();
        }
      }
    }
  }

  setFormValidator(form: FormGroup) {
    let controlField = [
      COURSE_CONST.SLIDE_TITLE,
      COURSE_CONST.EDITOR,
      COURSE_CONST.IMAGES,
      COURSE_CONST.VIDEOS,
      COURSE_CONST.AUDIOS,
    ];
    for (let index = 0; index < controlField.length; index++) {
      controlField[index];
      let updatedValidators = this.getUpdatedValidators(
        controlField[index],
        form?.get("layer2Options")?.value,
      );
      let control: any = form?.get(controlField[index]);
      control.setValidators(updatedValidators);
      control.updateValueAndValidity({ onlySelf: true, emitEvent: false });
    }
  }

  getUpdatedValidators(controlName: string, layer2Options: any): ValidatorFn[] {
    switch (controlName) {
      case COURSE_CONST.SLIDE_TITLE:
        switch (layer2Options) {
          default:
            return [];
        }
      case COURSE_CONST.EDITOR:
        switch (layer2Options) {
          case LAYER_OPTIONS.Text_Only:
          case LAYER_OPTIONS.L1_OP_2:
          case LAYER_OPTIONS.L1_OP_3:
            return [Validators.required];
          case LAYER_OPTIONS.L1_OP_4:
            return [];
          default:
            return [Validators.required];
        }
      case COURSE_CONST.IMAGES:
        switch (layer2Options) {
          case LAYER_OPTIONS.L1_OP_2:
            return [Validators.required];
          case LAYER_OPTIONS.Text_Only:
          case LAYER_OPTIONS.L1_OP_3:
          case LAYER_OPTIONS.L1_OP_4:
            return [];
          default:
            return [];
        }
      case COURSE_CONST.VIDEOS:
        switch (layer2Options) {
          case LAYER_OPTIONS.L1_OP_3:
          case LAYER_OPTIONS.L1_OP_4:
            return [Validators.required];
          case LAYER_OPTIONS.Text_Only:
          case LAYER_OPTIONS.L1_OP_2:
            return [];
          default:
            return [];
        }
      default:
        return [];
    }
  }

  resetingFormValuesOnLayerOptionChange(formGroup: FormGroup, index: number) {
    if (!this.submittingForm) {
      formGroup?.get(COURSE_CONST.SLIDE_TITLE)?.reset();
      formGroup?.get(COURSE_CONST.EDITOR)?.reset();
      (formGroup?.get(COURSE_CONST.IMAGES) as FormArray)?.clear();
      (formGroup?.get(COURSE_CONST.VIDEOS) as FormArray)?.clear();
      (formGroup?.get(COURSE_CONST.AUDIOS) as FormArray)?.clear();
      this.fileList[index] = [];
      this.audioFileList[index] = [];
      this.disableUploadButton = false;
    }
  }

  _showVideoPlayerOption(index: number) {
    let layerSelected =
      this.getFormGroupAtIndex(index)?.get("layer2Options")?.value;
    if (
      layerSelected == LAYER_OPTIONS.L1_OP_3 ||
      layerSelected == LAYER_OPTIONS.L1_OP_4
    ) {
      return true;
    }
    return false;
  }
}

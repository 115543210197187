
<div
  class="course-slide-wrapper"
  [ngClass]="{
    'common-min-height': !isIOS || !this.isTablet,
    'ios-min-height': isIOS && this.isTablet
  }"
>
  <div
    class="course-slide-header"
    *ngIf="!flgCongratulation && _courseGlobalConfig?.enableLogoandtitle"
  >
    <div class="logo">
      <img class="clientLog" [src]="logoUrl" id="clientLog" />
    </div>
    <div class="text-content">
      <h1 class="m-0">{{ course?.title }}</h1>
    </div>
  </div>

  <div class="pageTitle" *ngIf="!flgCongratulation">
    <ng-container *ngIf="course?.slides?.[slideIndex]?.stepTwo?.slideTitle">
      <div class="titleContainer">
        <h2 class="m-0">
          {{ course?.slides?.[slideIndex]?.stepTwo?.slideTitle }}
        </h2>
      </div>
    </ng-container>
    <ng-container *ngIf="course?.category?.toString().toLowerCase() == 'quiz'">
      <div class="titleContainer">
        <h2 class="m-0">
          {{ course?.slides?.[slideIndex]?.stepTwo?.questionTitle }}
        </h2>
      </div>
    </ng-container>

    <div class="pageInfoContainer">
      <span class="spanRight">
        Page {{ course?.slides?.length ? slideIndex + 1 : 0 }} of
        {{ course?.slides?.length }}
      </span>
    </div>
  </div>
  <app-congratulation [course]="course" [quizAttempts]="quizReultsToShow" *ngIf="!!flgCongratulation"></app-congratulation>

  <div
    class="course-slide-content"
    *ngIf="!flgCongratulation && course?.slides?.length"
  >
    <div
      class="slide-edit-options d-flex justify-content-center align-items-center"
      *ngIf="
        !flgCongratulation && isBuilder && !(course?.status === 'Completed')
      "
    >
      <button (click)="addSlide()" title="Insert a New Page Immediately After This Page" nz-button nzType="primary" nzSize="large"
        *ngIf="!(course?.status === 'Completed' || previewMode)"
        class="addBtn button-container add-icon-btn d-flex justify-content-center align-items-center">
        <span nz-icon nzType="plus" nzTheme="outline"></span>
      </button>
      
      <button
        (click)="editSlide()"
        title="Edit this page" 
        nz-button
        nzType="primary"
        nzSize="large"
        *ngIf="!(course?.status === 'Completed' || previewMode)"
        class="editBtn button-container edit-icon-btn d-flex justify-content-center align-items-center"
      >
        <span nz-icon nzType="edit" nzTheme="outline"></span>
      </button>
      <button
        (click)="deleteSlide()"
        title="Delete this page"
        nz-button
        nzSize="large"
        nzType="primary"
        *ngIf="!(course?.status == 'Completed' || course?.slides?.length == 0)"
        class="deleteBtn button-container delete-icon-btn d-flex justify-content-center align-items-center"
      >
        <span nz-icon nzType="delete" nzTheme="outline"></span>
      </button>
    </div>
    <app-slide
      #slideRef
      (assetClicked)="showLayerTwoContent($event)"
      (quizClicked)="quizPreViewDatGet($event)"
      [videoPlayOption]="course?.slides?.[slideIndex]?.stepOne?.videoOption"
      [buttonConfig]="{
        backgroundColor: course?.slides?.[slideIndex]?.stepOne?.buttonFillColor,
        activeBackgroundColor:
          course?.slides?.[slideIndex]?.stepOne?.buttonActiveColor,
        activeTextColor:
          course?.slides?.[slideIndex]?.stepOne?.buttonActiveTextColor,
        color: course?.slides?.[slideIndex]?.stepOne?.buttonTextColor,
        borderColor: course?.slides?.[slideIndex]?.stepOne?.buttonBorderColor
      }"
      [slideContent]="selectSlideContent(slideIndex)"
      [stepOne]="course?.slides?.[slideIndex]?.stepOne"
      [globalConfig]="course?.globalConfigs"
      [previewMode]="previewMode"
      [disabled]="isQuestionDisabled"
      [courseCategory]="course?.category"
    >
    </app-slide>
  </div>


  <div *ngIf="!flgCongratulation" class="course-slide-footer">
    <div class="left-button">
      <button
        nz-button
        nzType="primary"
        nzSize="large"
        (click)="handleExit()"
        [hidden]="previewMode"
        nz-button
        nzType="primary"
        class="button-container navigation"
      >
        {{ translationService.translate("EXIT") }}
      </button>
    </div>
    <div class="right-button">
      <div class="button-container">
        <button
          *ngIf="_courseGlobalConfig?.enableBackButton"
          (click)="goToPreviousSlide()"
          nz-button
          nzType="primary"
          nzSize="large"
          class="navigation"
          [hidden]="
            slideIndex === 0 || (course?.slides?.length == 0 && previewMode)
          "
        >
          <span nz-icon nzType="left" nzTheme="outline"></span>
          {{ translationService.translate("PREVIOUS") }}
        </button>
        <button
          nz-button
          nzType="primary"
          nzSize="large"
          class="navigation"
          [hidden]="course?.slides?.length === 0 && previewMode"
          [class.disabled]="
            (!this.isCurrentSlideFullyViewed() && course.category == 'edecoursecontent') ||
            (this.slideIndex + 1 === this.course?.slides?.length && this.previewMode)"
          (click)="handleNavigation()"
        >
          {{ translationService.translate("NEXT") }}
          <span nz-icon nzType="right" nzTheme="outline"></span>
        </button>
      </div>
    </div>
  </div>
  <div class="right-button" *ngIf="!!flgCongratulation">
    <div class="button-container" style="padding-right: 20px">
      <button
        nz-button
        nzType="primary"
        nzSize="large"
        class="navigation"
        (click)="redirectUrl()"
      >
        OK
      </button>
    </div>
  </div>
  <link
    rel="stylesheet"
    href="https://cdn.jsdelivr.net/npm/bootstrap@4.4.1/dist/css/bootstrap.min.css"
    integrity="sha384-Vkoo8x4CGsO3+Hhxv8T/Q5PaXtkKtu6ug5TOeNV6gBiFeWPGFN9MuhOf23Q9Ifjh"
    crossorigin="anonymous"
  />
</div>
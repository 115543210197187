<div class="headings">
  <h4 nz-typography>
    {{ "QUESTION_TYPE_CONFIG" | translate }}
  </h4>
</div>
<form
  nz-form
  *ngIf="questionTypeConfiguration"
  [nzLayout]="'vertical'"
  [formGroup]="questionTypeConfiguration"
  (ngSubmit)="submitForm()"
>
  <!--  -->
  <nz-form-item>
    <nz-form-label nzRequired nzFor="questionType">{{
      "QUESTION_TYPE" | translate
    }}</nz-form-label>
    <nz-form-control nzErrorTip="{{ 'SELECT_LAYER_PLACEHOLDER' | translate }}">
      <nz-select
        id="questionType"
        formControlName="questionType"
        nzPlaceHolder="{{ 'SELECT_PLACEHOLDER' | translate }}"
      >
        <nz-option
          *ngFor="let option of questionTypeOptions"
          [nzValue]="option.value"
          [nzLabel]="option.label"
        ></nz-option>
      </nz-select>
    </nz-form-control>
  </nz-form-item>
  <!--  -->
  <!-- enableNumberLabels -->
  <nz-form-item *ngIf="questionTypeConfiguration?.get('questionType')?.value === '4'">
    <nz-form-label nzRequired nzFor="enableNumberLabels">
      {{ "NUMBER_LABELS_CONFIG" | translate }}</nz-form-label>
    <nz-form-control nzErrorTip="{{ 'SELECT_LAYER_PLACEHOLDER' | translate }}">
      <nz-switch
        formControlName="enableNumberLabels"
        [nzCheckedChildren]="checkedTemplate"
        [nzUnCheckedChildren]="unCheckedTemplate"
      >
      </nz-switch>
    </nz-form-control>
  </nz-form-item>
  <!--  -->
    <!--  -->
  <nz-form-item>
    <nz-form-label nzRequired nzFor="isQuestionMandatoryForQssp">{{
      "QUESTION_MANDATORY_FOR_QSSP_CONFIG" | translate
    }}</nz-form-label>
    <nz-form-control nzErrorTip="{{ 'SELECT_LAYER_PLACEHOLDER' | translate }}">
      <nz-switch
        formControlName="isQuestionMandatoryForQssp"
        [nzCheckedChildren]="checkedTemplate"
        [nzUnCheckedChildren]="unCheckedTemplate"
        [nzDisabled]="true"
      >
      </nz-switch>
    </nz-form-control>
  </nz-form-item>
  <nz-form-item>
    <nz-form-label nzRequired nzFor="enableFreeFormAnswer">{{
      "FREE_FORM_FIELD_CONFIG" | translate
    }}</nz-form-label>
    <nz-form-control nzErrorTip="{{ 'SELECT_LAYER_PLACEHOLDER' | translate }}">
      <nz-switch
        formControlName="enableFreeFormAnswer"
        [nzCheckedChildren]="checkedTemplate"
        [nzUnCheckedChildren]="unCheckedTemplate"
      >
      </nz-switch>
    </nz-form-control>
  </nz-form-item>
  <!--  -->
  <nz-form-item *ngIf="questionTypeConfiguration?.get('enableFreeFormAnswer')?.value">
    <nz-form-label nzRequired nzFor="isFreeFormQuestionMandatory">{{
      "FREE_FORM_MANDATORY_CONFIG" | translate
    }}</nz-form-label>
    <nz-form-control nzErrorTip="{{ 'SELECT_LAYER_PLACEHOLDER' | translate }}">
      <nz-switch
        formControlName="isFreeFormQuestionMandatory"
        [nzCheckedChildren]="checkedTemplate"
        [nzUnCheckedChildren]="unCheckedTemplate"
      >
      </nz-switch>
    </nz-form-control>
  </nz-form-item>
  <!--  -->

  <!--  -->
  
  <!--  -->

  <!-- <nz-form-item>
    <nz-form-label nzRequired nzFor="displayCheckAnswerButton">{{
      "DISPLAY_CHECK_ANSWER_BTN_CONFIG" | translate
    }}</nz-form-label>
    <nz-form-control nzErrorTip="{{ 'SELECT_LAYER_PLACEHOLDER' | translate }}">
      <nz-switch
        formControlName="displayCheckAnswerButton"
        [nzCheckedChildren]="checkedTemplate"
        [nzUnCheckedChildren]="unCheckedTemplate"
      >
      </nz-switch>
    </nz-form-control>
  </nz-form-item> -->
  <!--  -->
  <nz-form-item *ngIf="['1', '2'].includes(questionTypeConfiguration?.get('questionType')?.value)">
    <nz-form-label nzRequired nzFor="correctAnswerProceeding">{{
      "IF_ANSWER_CORRECTLY_CONFIG" | translate
    }}</nz-form-label>
    <nz-form-control nzErrorTip="{{ 'SELECT_LAYER_PLACEHOLDER' | translate }}">
      <nz-select
        id="correctAnswerProceeding"
        formControlName="correctAnswerProceeding"
        nzPlaceHolder="{{ 'SELECT_PLACEHOLDER' | translate }}"
      >
        <nz-option
          *ngFor="let option of correctAnswerProceedingOptions"
          [nzValue]="option.value"
          [nzLabel]="option.label"
        ></nz-option>
      </nz-select>
    </nz-form-control>
  </nz-form-item>
  <!--  -->

  <!--  -->
  <nz-form-item *ngIf="['1', '2'].includes(questionTypeConfiguration?.get('questionType')?.value)">
    <nz-form-label nzRequired nzFor="incorrectAnswerProceeding">{{
      "IF_ANSWER_INCORRECTLY_CONFIG" | translate
    }}</nz-form-label>
    <nz-form-control nzErrorTip="{{ 'SELECT_LAYER_PLACEHOLDER' | translate }}">
      <nz-select
        id="incorrectAnswerProceeding"
        formControlName="incorrectAnswerProceeding"
        nzPlaceHolder="{{ 'SELECT_PLACEHOLDER' | translate }}"
      >
        <nz-option
          *ngFor="let option of incorrectAnswerProceedingOptions"
          [nzValue]="option.value"
          [nzLabel]="option.label"
        ></nz-option>
      </nz-select>
    </nz-form-control>
  </nz-form-item>
  <!--  -->

  <!--  -->
  <nz-form-item *ngIf="['1', '2'].includes(questionTypeConfiguration?.get('questionType')?.value) && questionTypeConfiguration?.get('incorrectAnswerProceeding')?.value == '3'">
    <nz-form-label nzRequired nzFor="maximumAttempt">{{
      "MAXIMUM_NUMBER_OF_ATTEMPT_CONFIG" | translate
    }}</nz-form-label>
    <nz-form-control nzErrorTip="{{ 'SELECT_LAYER_PLACEHOLDER' | translate }}">
      <nz-select
        id="maximumAttempt"
        formControlName="maximumAttempt"
        nzPlaceHolder="{{ 'SELECT_PLACEHOLDER' | translate }}"
      >
        <nz-option
          *ngFor="let option of numberOfRetriesOption"
          [nzValue]="option.value"
          [nzLabel]="option.label"
        ></nz-option>
      </nz-select>
    </nz-form-control>
  </nz-form-item>
  <!--  -->

  <!--  -->
  <!--  -->

  <!--  -->
  <nz-form-item *ngIf="['1', '2'].includes(questionTypeConfiguration?.get('questionType')?.value)">
    <nz-form-label nzRequired nzFor="questionPoint">{{
      "QUESTION_POINT_CONFIG" | translate
    }}</nz-form-label>
    <nz-form-control nzErrorTip="{{ 'SELECT_LAYER_PLACEHOLDER' | translate }}">
      <nz-select
        id="questionPoint"
        formControlName="questionPoint"
        nzPlaceHolder="{{ 'SELECT_PLACEHOLDER' | translate }}"
      >
        <nz-option
          *ngFor="let option of numberOfPointOption"
          [nzValue]="option.value"
          [nzLabel]="option.label"
        ></nz-option>
      </nz-select>
    </nz-form-control>
  </nz-form-item>
  <!--  -->

  <!--  -->
  <nz-form-item *ngIf="['1', '2'].includes(questionTypeConfiguration?.get('questionType')?.value)">
    <nz-form-label nzRequired nzFor="correctAnswerPoint">{{
      "CORRECT_ANSWER_POINT_CONFIG" | translate
    }}</nz-form-label>
    <nz-form-control nzErrorTip="{{ 'SELECT_LAYER_PLACEHOLDER' | translate }}">
      <nz-select
        id="correctAnswerPoint"
        formControlName="correctAnswerPoint"
        nzPlaceHolder="{{ 'SELECT_PLACEHOLDER' | translate }}"
      >
        <nz-option
          *ngFor="let option of numberOfPointOption"
          [nzValue]="option.value"
          [nzLabel]="option.label"
        ></nz-option>
      </nz-select>
    </nz-form-control>
  </nz-form-item>
  <!--  -->
</form>

<!-- Toggle Switch Template -->
<ng-template #checkedTemplate>
  <span nz-icon nzType="check"></span>
</ng-template>
<ng-template #unCheckedTemplate>
  <span nz-icon nzType="close"></span>
</ng-template>

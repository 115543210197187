import { Component } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { NzMessageService } from "ng-zorro-antd/message";
import { LocalStorageUtils, Utils } from "../../../core/utils";
import { CourseService } from "../../../services/course.service";
import { CATEGORIES, COURSE_CONST } from "../../data";
import { CourseApiService } from "../../../services/course.api.service";
import {
  CourseRequest,
  CourseResponse,
} from "../../../models/course-interface";
import { SpinnerService } from "src/app/services/spinner.service";
import { TranslationService } from "src/app/services/translation.service";
import { NzModalService } from "ng-zorro-antd/modal";

@Component({
  selector: "app-home",
  templateUrl: "./course-home.component.html",
  styleUrls: ["./course-home.component.scss"],
})
export class CourseHomeComponent {
  courseId: number = 0;
  foundedCourse: any;
  courseCreateResponse$: any;
  course: any;
  mode!: string;
  eventName: string = "";
  enableLogoandtitle: boolean = false;
  enableAudioAutoplay: boolean = false;
  categoryName!: string;
  editDisabled!: boolean;
  courses: any[] = [];
  foundCourse = {};
  categories: string[] = CATEGORIES;

  constructor(
    private router: Router,
    private message: NzMessageService,
    private activateRoute: ActivatedRoute,
    private courseService: CourseService,
    private courseApiService: CourseApiService,
    private spinner: SpinnerService,
    private modalService: NzModalService,
    public translationService: TranslationService,
  ) {}

  ngOnInit(): void {
    Utils.checkAuthorization(this.router);
    this.courseService.applyStyles();
    this.courseService.deleteCourseBuilderSteps();
    this.handleCategories();
    this.editDisabled = this.courseService.isEditDisabled();
    this.getCourseMode();
  }

  handleCategories() {
    this.categoryName = "edeCourseContent";
  }

  getCourseMode() {
    this.courseId = Number(
      this.courseService.getQueryParamByName(this.activateRoute, "courseId") ||
        0,
    );
    if (this.courseId > 0) {
      this.spinner.show();
      this.courseApiService.getCourse(this.courseId).subscribe(
        (response: any) => {
          this.spinner.hide();
          this.course = response.data.attributes;
          this.mode = COURSE_CONST.UPDATE_MODE;
          this.foundedCourse = this.course;
          this.eventName = this.course.title;
          this.enableLogoandtitle =
            this.course.globalConfigs &&
            typeof this.course.globalConfigs == "string"
              ? JSON.parse(this.course.globalConfigs)?.enableLogoandtitle
              : this.course.globalConfigs?.enableLogoandtitle || false;
          this.enableAudioAutoplay =
            this.course.globalConfigs &&
            typeof this.course.globalConfigs == "string"
              ? JSON.parse(this.course.globalConfigs)?.enableAudioAutoplay
              : this.course.globalConfigs?.enableAudioAutoplay || false;
        },
        (error: any) => {
          this.spinner.hide();
        },
      );
    }
  }

  handleExit(): void {
    if (this.mode == COURSE_CONST.CREATE_MODE) {
      this.goBackToHome();
    } else {
      this.router.navigate(["/view-courses"], {
        queryParams: { category: this.categoryName },
      });
    }
  }

  showMessageDialog(message: string) {
    this.modalService.create({
      nzTitle: this.translationService.translate("INFORMATION"),
      nzContent: message,
      nzOkText: "OK",
      nzClosable: false,
      nzCancelText: null, // or nzCancelText: ''
      nzOnOk: () => {},
    });
  }

  handleSave(): void {
    if (this.verifyEventName()) return;
    if (this.mode == COURSE_CONST.CREATE_MODE) {
      this.courseId = 0;
      this.spinner.show();
      this.courseCreateResponse$ = this.courseApiService
        .createCourse(this.getCourseRequest())
        .subscribe(
          (response: any) => {
            this.spinner.hide();
          },
          (error: any) => {
            this.spinner.hide();
          },
        );
    } else {
      this.spinner.show();
      const courseRequest: CourseRequest = {
        data: {
          title: this.eventName.trim(),
          globalConfigs: this.getGlobalConfig(),
        },
      };

      this.courseApiService
        .updateCourse(this.courseId, courseRequest)
        .subscribe(
          (response: any) => {
            this.spinner.hide();
          },
          (error: any) => {
            this.spinner.hide();
          },
        );
    }
  }
  // updateCourse(id: any, courseRequest: CourseRequest) {
  //   this.courseApiService
  //   .updateCourse(id, courseRequest)
  //   .subscribe(
  //     (response: any) => {
  //       this.router.navigate(['/view-courses']);
  //     },
  //     (error: any) => {
  //       this.loading = false;
  //     },
  //   );
  // }

  verifyEventName() {
    if (
      (this.mode === COURSE_CONST.CREATE_MODE ||
        this.mode === COURSE_CONST.UPDATE_MODE) &&
      this.eventName.trim().length < 1
    ) {
      this.showMessageDialog(
        this.translationService.translate("PLEASE_ENTER_NAME_LABEL"),
      );
      return true;
    }

    return false;
  }

  handleCreateNewCourse(): void {
    if (this.categoryName == "quiz") {
      console.log("Navigate to Quiz Home");
      this.router.navigate(["/quiz"]);
    } else {
      this.mode = "CREATE";
    }
  }

  handleNavigation(): void {
    if (this.verifyEventName()) return;

    if (this.mode == COURSE_CONST.UPDATE_MODE) {
      this.updateCourse();
    } else {
      this.createCourse();
    }
  }

  createCourse() {
    this.spinner.show();
    this.courseApiService.createCourse(this.getCourseRequest()).subscribe(
      (response: CourseResponse) => {
        this.navigateToEditCourse(response?.id);
        this.spinner.hide();
      },
      (error: any) => {
        this.spinner.hide();
      },
    );
  }

  updateCourse() {
    const courseRequest: CourseRequest = {
      data: {
        title: this.eventName,
        globalConfigs: this.getGlobalConfig(),
        status: COURSE_CONST.NOT_COMPLETED,
      },
    };
    this.spinner.show();
    this.courseApiService.updateCourse(this.courseId, courseRequest).subscribe(
      (response: any) => {
        this.navigateToEditCourse(this.courseId);
        this.spinner.hide();
      },
      (error: any) => {
        this.spinner.hide();
      },
    );
  }

  navigateToEditCourse(courseId: any) {
    this.router.navigate([`/edit-${this.categoryName}/${courseId}`], {
      queryParams: { step: 2, slide: 1 },
    });
  }

  getGlobalConfig() {
    const globalConfigs = {
      enableLogoandtitle: this.enableLogoandtitle,
      enableAudioAutoplay: this.enableAudioAutoplay,
      enableBackButton: true,
    };

    return JSON.stringify(globalConfigs);
  }

  getCourseRequest() {
    const courseRequest: CourseRequest = {
      data: {
        title: this.eventName.trim(),
        globalConfigs: this.getGlobalConfig(),
        category: this.categoryName,
        totalSlideCount: 0,
        version: 1,
        duplicated: false,
        slides: "[]",
        status: "Not-Completed",
        currentVersion: false,
        courseVersionIdentifier: "",
        user: LocalStorageUtils.getUser()?.id.toString(),
        client: LocalStorageUtils.getClient()?.id.toString(),
      },
    };
    return courseRequest;
  }

  goBack(): void {
    this.mode = "";
  }

  goBackToHome(): void {
    this.router.navigate(["/"]);
  }

  validateUpdateOrCreate() {
    return this.mode === "CREATE" || this.mode === "UPDATE";
  }

  notMode() {
    return !this.mode;
  }
}

import { Component } from "@angular/core";
import { CourseService } from "../services/course.service";
import { AuthService } from "../services/auth.service";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthRequest, AuthResponse } from "../models/auth-response.interface";
import { environment } from "src/environments/environment";
import { SpinnerService } from "src/app/services/spinner.service";
import { LocalStorageUtils } from "../core/utils";

@Component({
  selector: "app-start",
  templateUrl: "./start.component.html",
  styleUrls: ["./start.component.scss"],
})
export class StartComponent {
  styleObject: any;
  userId: any;
  database: any;
  secretKey: any;
  regId: any;
  authResponse$: any;
  authResponseObj!: AuthResponse;
  courseId: any;
  clientLogoUrl!: string;
  flgResponseSuccessfull: boolean = false;
  loading: boolean = false;
  userFullName: string = "";

  constructor(
    private courseService: CourseService,
    private authService: AuthService,
    private activateRoute: ActivatedRoute,
    private router: Router,
    private spinner: SpinnerService,
  ) {}
  ngOnInit(): void {
    this.setQueryParams();
    if (this.userId && this.secretKey && this.database) {
      this.callAuthApi();
    }
  }

  setQueryParams() {
    this.activateRoute.queryParams.subscribe((params) => {
      // Read and assign query parameters
      this.userId = params["userId"];
      this.database = params["database"];
      this.secretKey = params["secretKey"];
      this.courseId = params["courseId"];
      this.regId = params["regId"];
    });
    this.courseService.applyStyles(this.database);
    this.clientLogoUrl = LocalStorageUtils.getClient()?.clientLogo
      ? environment.logoUrl + LocalStorageUtils.getClient()?.clientLogo
      : "";
    this.userFullName = LocalStorageUtils.getUser()?.userFullName;
  }

  callAuthApi() {
    let authRequestData: AuthRequest = {
      userId: this.userId,
      secretKey: this.secretKey,
      database: this.database,
    };

    this.spinner.show();
    this.authResponse$ = this.authService
      .validateSecretKey(authRequestData)
      .subscribe(
        (response: AuthResponse) => {
          LocalStorageUtils.saveToLocalStorage(
            "authRequestData",
            authRequestData,
          );
          if (this.courseId) {
            LocalStorageUtils.saveToLocalStorage("courseId", this.courseId);
          }
          if (this.regId) {
            LocalStorageUtils.saveToLocalStorage("regId", this.regId);
          }
          this.courseService.applyStyles(this.database);

          this.authResponseObj = response;
          this.clientLogoUrl =
            environment.logoUrl + this.authResponseObj.client.clientLogo;
          this.userFullName = this.authResponseObj.user.userFullName;

          this.spinner.hide();
          this.flgResponseSuccessfull = true;
          this.redirect();
        },
        (error: any) => {
          this.spinner.hide();
          this.router.navigate(["/not-found"]);
        },
      );
  }

  redirect() {
    const courseId =
      this.courseId || LocalStorageUtils.retrieveFromLocalStorage("courseId");

    const isBuilder = LocalStorageUtils.isUserBuilder();

    if (courseId && !isBuilder) {
      this.router.navigate([`/view-courses/${courseId}`]);
    } else {
      this.router.navigate(["/"]);
    }
  }
}

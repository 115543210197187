<div class="container">
  <div class="inner-container">
    <h1>{{ "404" | translate }}</h1>
    <p>{{ "NOT_FOUND_MESSAGE" | translate }}</p>
    <p>
      {{ "GO_BACK" | translate }}
      <a (click)="getRedirectUrl()">{{
        "HOME" | translate
      }}</a>
    </p>
  </div>
</div>

<div class="course-slide-wrapper">
  <!-- <div class="course-slide-content">
    <div class="row">
      <div class="col-md-12">
        <div class="card card-custom">
          <div class="card-body d-flex justify-content-center align-items-center flex-column">
            <div *ngIf="clientLogoUrl" class="clientLogo"> 
              <img [src]="clientLogoUrl" />
            </div>
            <p class="congratulations-text">
              {{ 'WELCOME_TO_COURSE' | translate }} <b>{{ userFullName }}</b> {{ 'WELCOME_TO_COURSE_TEXT_2' | translate }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="next-button">
    <div class="button-container">
      <button nz-button nzType="primary" (click)="redirect()">{{ 'OK' | translate }}</button>
    </div>
  </div> -->
  <link
    rel="stylesheet"
    href="https://cdn.jsdelivr.net/npm/bootstrap@4.4.1/dist/css/bootstrap.min.css"
    integrity="sha384-Vkoo8x4CGsO3+Hhxv8T/Q5PaXtkKtu6ug5TOeNV6gBiFeWPGFN9MuhOf23Q9Ifjh"
    crossorigin="anonymous"
  />
</div>

<div class="headings">
  <h4 nz-typography>
    {{ "SLIDE_TYPE_TEXT" | translate }}
  </h4>
</div>
<form
  nz-form
  *ngIf="validateForm"
  [nzLayout]="'vertical'"
  [formGroup]="validateForm"
  (ngSubmit)="submitForm()"
>
  <!--  -->
  <nz-form-item>
    <nz-form-label nzRequired nzFor="slideTypeOptions">{{
      "SLIDE_TYPE" | translate
    }}</nz-form-label>
    <nz-form-control nzErrorTip="{{ 'SELECT_LAYER_PLACEHOLDER' | translate }}">
      <nz-select
        id="slideTypeOptions"
        formControlName="layer"
        nzPlaceHolder="{{ 'SELECT_PLACEHOLDER' | translate }}"
        (ngModelChange)="onLayerSelect($event)"
      >
        <nz-option
          *ngFor="let option of slideTypeOptions"
          [nzValue]="option.value"
          [nzLabel]="option.label"
        ></nz-option>
      </nz-select>
    </nz-form-control>
  </nz-form-item>
  <!--  -->
  <nz-form-item>
    <nz-form-label nzRequired nzFor="layerOptions">{{
      "LAYER_OPTIONS" | translate
    }}</nz-form-label>
    <nz-form-control
      nzErrorTip="{{ 'SELECT_LAYER_OPTION_PLACEHOLDER' | translate }}"
    >
      <nz-select
        id="layerOptions"
        formControlName="layerOptions"
        nzPlaceHolder="{{ 'SELECT_PLACEHOLDER' | translate }}"
        (ngModelChange)="onLayerOptionSelect($event)"
      >
        <nz-option
          *ngFor="let option of currentLayerOptions"
          [nzValue]="option.value"
          [nzLabel]="option.label"
        ></nz-option>
      </nz-select>
    </nz-form-control>
  </nz-form-item>
  <!--  -->
  <nz-form-item>
    <nz-form-label nzRequired nzFor="enabledNext"
      >{{ "SLIDE_COMPLETION_RULE" | translate }}
    </nz-form-label>
    <nz-form-control nzErrorTip="{{ 'SELECT_LAYER_PLACEHOLDER' | translate }}">
      <nz-select
        id="enabledNext"
        formControlName="enabledNext"
        nzPlaceHolder="{{ 'SELECT_PLACEHOLDER' | translate }}"
      >
        <nz-option
          *ngFor="let option of completionRuleOptions"
          [nzValue]="option.value"
          [nzLabel]="option.label"
        ></nz-option>
      </nz-select>
    </nz-form-control>
  </nz-form-item>
  <nz-form-item>
    <nz-form-label nzRequired nzFor="pageLayoutOption">
      {{ "SLIDE_PAGE_LAYOUT" | translate }}
    </nz-form-label>
    <nz-form-control
      nzErrorTip="{{ 'SELECT_PAGE_LAYOUT_OPTION_PLACEHOLDER' | translate }}"
    >
      <nz-select
        id="pageLayoutOption"
        formControlName="pageLayoutOption"
        nzPlaceHolder="{{ 'SELECT_PLACEHOLDER' | translate }}"
      >
        <nz-option
          *ngFor="let option of pageLayoutOptions"
          [nzValue]="option.value"
          [nzLabel]="option.label"
        ></nz-option>
      </nz-select>
    </nz-form-control>
  </nz-form-item>
  <!--  -->
  <nz-form-item *ngIf="showVideoPlayingSelector">
    <nz-form-label nzRequired nzFor="videoOption"
      >{{ "SLIDE_VIDEO_PLAYER_OPTION" | translate }}
    </nz-form-label>
    <nz-form-control
      nzErrorTip="{{ 'SELECT_VIDEO_PLAYER_OPTION_PLACEHOLDER' | translate }}"
    >
      <nz-select
        id="videoOption"
        formControlName="videoOption"
        nzPlaceHolder="{{ 'SELECT_PLACEHOLDER' | translate }}"
      >
        <nz-option
          *ngFor="let option of videoPlayerOptions"
          [nzValue]="option.value"
          [nzLabel]="option.label"
        ></nz-option>
      </nz-select>
    </nz-form-control>
  </nz-form-item>
  <!--  -->
  <nz-form-item *ngIf="showButtonConfiguration">
    <nz-form-label nzRequired nzFor="buttonLayout"
      >{{ "SLIDE_BUTTON_LAYOUT" | translate }}
    </nz-form-label>
    <nz-form-control
      nzErrorTip="{{ 'SELECT_VIDEO_PLAYER_OPTION_PLACEHOLDER' | translate }}"
    >
      <nz-select
        id="buttonLayout"
        formControlName="buttonLayout"
        nzPlaceHolder="{{ 'SELECT_PLACEHOLDER' | translate }}"
      >
        <nz-option
          *ngFor="let option of buttonLayoutOptions"
          [nzValue]="option.value"
          [nzLabel]="option.label"
        ></nz-option>
      </nz-select>
    </nz-form-control>
  </nz-form-item>
  <!--  -->
  <nz-form-item *ngIf="showButtonConfiguration">
    <nz-form-label nzRequired nzFor="buttonAppearance"
      >{{ "SLIDE_BUTTON_APPEARANCE" | translate }}
    </nz-form-label>
    <nz-form-control
      nzErrorTip="{{ 'SELECT_VIDEO_PLAYER_OPTION_PLACEHOLDER' | translate }}"
    >
      <nz-select
        id="buttonAppearance"
        formControlName="buttonAppearance"
        nzPlaceHolder="{{ 'SELECT_PLACEHOLDER' | translate }}"
      >
        <nz-option
          *ngFor="let option of buttonAppearancOption"
          [nzValue]="option.value"
          [nzLabel]="option.label"
        ></nz-option>
      </nz-select>
    </nz-form-control>
  </nz-form-item>
  <!--  -->
  <div
    class="d-flex justify-content-between align-items-center"
    *ngIf="showButtonConfiguration"
  >
    <div class="w-19">
      <nz-form-item>
        <nz-form-label nzRequired nzFor="buttonFillColor"
          >{{ "SLIDE_BUTTON_FILL_COLOR" | translate }}
        </nz-form-label>
        <nz-form-control
          nzErrorTip="{{ 'SELECT_COLOR_OPTION_PLACEHOLDER' | translate }}"
        >
          <nz-select
            id="buttonFillColor"
            formControlName="buttonFillColor"
            nzPlaceHolder="{{ 'SELECT_PLACEHOLDER' | translate }}"
          >
            <nz-option
              *ngFor="let option of buttonConfigOptions"
              [nzValue]="option.value"
              [nzLabel]="option.label"
            ></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
    </div>
    <div class="w-19">
      <nz-form-item>
        <nz-form-label nzRequired nzFor="buttonBorderColor"
          >{{ "SLIDE_BUTTON_BORDER_COLOR" | translate }}
        </nz-form-label>
        <nz-form-control
          nzErrorTip="{{ 'SELECT_COLOR_OPTION_PLACEHOLDER' | translate }}"
        >
          <nz-select
            id="buttonBorderColor"
            formControlName="buttonBorderColor"
            nzPlaceHolder="{{ 'SELECT_PLACEHOLDER' | translate }}"
          >
            <nz-option
              *ngFor="let option of buttonConfigOptions"
              [nzValue]="option.value"
              [nzLabel]="option.label"
            ></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
    </div>
    <div class="w-19">
      <nz-form-item>
        <nz-form-label nzRequired nzFor="buttonTextColor"
          >{{ "SLIDE_BUTTON_TEXT_COLOR" | translate }}
        </nz-form-label>
        <nz-form-control
          nzErrorTip="{{ 'SELECT_COLOR_OPTION_PLACEHOLDER' | translate }}"
        >
          <nz-select
            id="buttonTextColor"
            formControlName="buttonTextColor"
            nzPlaceHolder="{{ 'SELECT_PLACEHOLDER' | translate }}"
          >
            <nz-option
              *ngFor="let option of buttonConfigOptions"
              [nzValue]="option.value"
              [nzLabel]="option.label"
            ></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
    </div>
    <div class="w-19">
      <nz-form-item>
        <nz-form-label nzRequired nzFor="buttonActiveColor"
          >{{ "SLIDE_BUTTON_ACTIVE_COLOR" | translate }}
        </nz-form-label>
        <nz-form-control
          nzErrorTip="{{ 'SELECT_COLOR_OPTION_PLACEHOLDER' | translate }}"
        >
          <nz-select
            id="buttonActiveColor"
            formControlName="buttonActiveColor"
            nzPlaceHolder="{{ 'SELECT_PLACEHOLDER' | translate }}"
          >
            <nz-option
              *ngFor="let option of buttonConfigOptions; let i = index"
              [nzValue]="option.value"
              [nzLabel]="option.label"
              [nzDisabled]="i == 0"
            ></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
    </div>
    <div class="w-19">
      <nz-form-item>
        <nz-form-label nzRequired nzFor="buttonActiveTextColor"
          >{{ "SLIDE_BUTTON_ACTIVE_TEXT_COLOR" | translate }}
        </nz-form-label>
        <nz-form-control
          nzErrorTip="{{ 'SELECT_COLOR_OPTION_PLACEHOLDER' | translate }}"
        >
          <nz-select
            id="buttonActiveTextColor"
            formControlName="buttonActiveTextColor"
            nzPlaceHolder="{{ 'SELECT_PLACEHOLDER' | translate }}"
          >
            <nz-option
              *ngFor="
                let option of buttonConfigOptions | slice: 0 : 3;
                let i = index
              "
              [nzValue]="option.value"
              [nzLabel]="option.label"
            ></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
    </div>
  </div>
  <!--  -->
  <nz-form-item> </nz-form-item>
</form>

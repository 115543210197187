<div id="select-container">
  <ul>
    <li
      *ngFor="let lang of languageData; let i = index"
      flow="down"
      (click)="changeLanguage(lang)"
      [ngClass]="{
        'h-10': i != 0
      }"
    >
      <span>{{ lang.code }}</span>
    </li>
  </ul>
</div>

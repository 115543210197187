import { Component, Inject } from "@angular/core";
import { NZ_MODAL_DATA } from "ng-zorro-antd/modal";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";

@Component({
  selector: "app-preview",
  templateUrl: "./preview.component.html",
  styleUrls: ["./preview.component.scss"],
})
export class PreviewComponent {
  sanitizedUrl: SafeResourceUrl;

  constructor(
    @Inject(NZ_MODAL_DATA)
    public data: any,
    private sanitizer: DomSanitizer,
  ) {
    this.sanitizedUrl = this.sanitizer.bypassSecurityTrustResourceUrl(data.url);
  }
}

<div class="headings">
  <h4 nz-typography>
    {{ "LAYER1_SLIDE_TITLE" | translate }}
    <span class="italic" nz-typography>*{{ "MANDATORY" | translate }}</span>
  </h4>
</div>

<form
  nz-form
  [nzLayout]="'vertical'"
  [formGroup]="validateForm"
  (ngSubmit)="submitForm()"
>
  <div *ngIf="validateForm && selectedOption">
    <app-content-editor
      [parentForm]="validateForm"
      [fileList]="fileList"
      [componentName]="courseConst.STEP_TWO"
      [audioFileList]="audioFileList"
      [selectedOption]="selectedOption"
      [isFormSubmit]="isFormSubmit"
      [hotspots]="hotspotList"
      (formSubmitted)="submitForm($event)"
      (imageDelete)="deletingImage($event)"
      [indexOfActiveContentEditor]="0"
      [indexOfContentEditor]="0"
      [buttonConfig]="{
        backgroundColor: this.courseObject?.stepOne?.buttonFillColor,
        color: this.courseObject?.stepOne?.buttonTextColor,
        borderColor: this.courseObject?.stepOne?.buttonBorderColor
      }"
    >
    </app-content-editor>
  </div>
</form>
